import React, { useEffect, useState } from 'react';
import styles from './ProfessionalInfo.module.css';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';

const WorkExperienceComponent = () => {
  const [showAll, setShowAll] = useState(false);
  const [experience, setExperience] = useState([]);

  const toggleShow = () => {
    setShowAll(!showAll);
  };
  const token = getToken();

  useEffect(() => {
    getUserProfile(token)
      .then((res) => {
        if (res && res.applicant_profile && Array.isArray(res.applicant_profile.work_experience)) {
          setExperience(res.applicant_profile.work_experience);
          console.log('res.applicant_profile.work_experience', res)
        } else {
          setExperience([]); // Set to empty array if work_experience is not an array or is missing
        }
      })
      .catch((error) => {
        console.error("Failed to fetch user profile:", error);
        setExperience([]); // Set to empty array if there's an error
      });
  }, []);


  {
    const displayedExperiences = showAll ? experience : experience.slice(0, 2);
    Array.isArray(displayedExperiences) && displayedExperiences.map((job, index) => {
      const isLast = index === displayedExperiences.length - 1;

      return (
        <div key={index} className={styles.BorderDiv} style={{ borderBottom: isLast ? 'none' : '1px solid #ccc' }}>
          <div className='d-flex justify-content-between'>
            <div className={styles.CurrentJob}>
              <span> Job</span>
              <h6>{job.job_title} at {job.organization} </h6>
            </div>
            <div className={styles.CurrentJob}>
              <span>Experience </span>
              <h6>{job.year_experience}</h6>
            </div>
          </div>
          <h6><strong>References:</strong></h6>
          <ul className='d-flex justify-content-around border p-3'>
            <li>
              <div className='d-flex flex-column justify-content-around'>
                <span>{job.reference_name}</span>
                <span>{job.reference_jobtitle}</span>
              </div>
            </li>
            <li>
              <div className='d-flex flex-column justify-content-around'>
                <span>Phone</span>
                <span>{job.reference_phone}</span>
              </div>
            </li>
            <li>
              <div className='d-flex flex-column justify-content-around'>
                <span>Relationship</span>
                <span>{job.reference_relationship}</span>
              </div>
            </li>
          </ul>
        </div>
      );
    })
  }

};

export default WorkExperienceComponent;
