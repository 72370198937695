import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"

const TotalNumberCard = () =>{
    return (
        <Box sx={{
            border: "0.411782px solid #EAEEF4",
            boxShadow: "0px 2.79149px 2.79149px rgba(0, 0, 0, 0.25)",
            display:"flex", 
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center"
            }}
            py={1}
            px={2}>
                <Typography color="#263997">Total Number of Vacancies</Typography>
                <Typography color="#F0940A">800</Typography>
            </Box>
    )
}
export default TotalNumberCard