import Box from "@mui/material/Box";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { Companies } from "../../components/Companies/Companies";
import OpenJobs from "../../components/OpenJobs/OpenJobs";
import { getAllCompanies, getComapnyProfile, getUserProfile } from '../../Middleware/CandidateApis';
import { getToken, getUserData } from '../../Middleware/Constans';
import HeroSection from "../Hero-Section/HeroSection";
import PartnersSection from "../Partners-Section/PartnersSection";
import { PostingJobs } from "../PostingJobs/PostingJobs";
import WhyToUseSection from "../Why-To-Use-Section/WhyToUseSection";
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
  palette: {
    primary: {
      main: "#06003a",
    },
    secondary: {
      main: "#FDAD10",
    },
    text: {
      main: "#7A7A7A",
    },
    accent: {
      main: "#61CE70",
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      color: "white",
    },
    h2: {
      fontSize: "28px",
      color: "white",
    },
    h4: {
      fontWeight: "100",
      color: "text.main",
    },
    h5: {
      fontWeight: "100",
      color: "text.main",
    },
  },
});

function Home() {
  const [companyData, setComapnyData] = useState([]);
  const userData = getUserData();
  const navigate = useNavigate();

  const token = getToken();
  useEffect(() => {
    if (userData.user_type === "company") {
      getComapnyProfile(userData.company_name).then((res) => {
        // 
        if (res.company_profile.completed === false) {
          window.location.href = (`/companyProfile/${userData.company_slug}/editProfile`);
        }
      }).catch(error => {
        console.error("Error fetching company profile:", error);
      });
    }
  }, [userData, navigate]);

  useEffect(() => {
    if (userData.user_type === "applicant") {
      getUserProfile(token).then((res) => {
        
        if (res.completed === false) {
          window.location.href = (`/user-profile/edit-profile`);
        }
      }).catch(error => {
        console.error("Error fetching company profile:", error);
      });
    }
  }, [userData, navigate]);

  useEffect(() => {
    document.title = "Hiring Portal - Home";
  }, []);

  useEffect(() => {
    getAllCompanies().then((res) => {
      // console.log(res.results, "res.results");
      const data = res.results.slice(0, 6);
      setComapnyData(data);
    }).catch((error) => {
      
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <HeroSection />
        <PartnersSection />
        <WhyToUseSection />
        <PostingJobs />
        <Companies Sectiontitle="Companies" showCompaniesButton CompniesData={companyData} />
        {userData.user_type !== "company" && (
          <OpenJobs title="Latest jobs open" />
        )}
      </Box>
    </ThemeProvider>
  );
}

export default Home;
