import React, { useEffect, useState } from 'react';
import styles from './ProfessionalInfo.module.css';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';

const EducationComponent = () => {
  const [education, setEducation] = useState([]);
  const token = getToken();

  useEffect(() => {
    getUserProfile(token)
      .then((res) => {
        if (res && res.applicant_profile && Array.isArray(res.applicant_profile.education)) {
          setEducation(res.applicant_profile.education);
          console.log('res', res)
        } else {
          setEducation([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("Failed to fetch education data:", error);
        setEducation([]); // Fallback to an empty array in case of an error
      });
  }, [token]);

  const getYear = (dateString) => {
    const date = new Date(dateString);
    return date.getFullYear();
  };

  return (
    <div className='Education '>
      <h6 className={styles.textProfessional}>Education</h6>
      <ul>
        {education.map((edu, index) => (
          <li key={index} className='d-flex flex-column border my-1 p-3'>
            <h5 className={styles.edu}>{edu.university}</h5>
            <span>{edu.degree}</span>
            <span>{edu.college}</span>
            <span>From : {getYear(edu.fromm)} - To : {getYear(edu.to)}</span>
            <span>Grade: {edu.grade}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EducationComponent;
