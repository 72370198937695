import {
    Button,
    Grid,
    MenuItem,
    Select,
    Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { API_URL } from "../../config";

const theme = createTheme({
    typography: {
        fontFamily: "Poppins",
    },
});


function Attachment() {
    const [loading, setLoading] = useState(false);
    const [militaryStatus, setMilitaryStatus] = useState();
    const [myResume, setMyResume] = useState();
    const [files, setFiles] = useState([1])
    const navigate = useNavigate();

    // function deleteElement(e, i) {
    //     )
    //     
    //     
    //     let test = files.filter((ele) => ele !== i)
    //     
    //     setFiles(test)
    // }
    // function addeElement() {
    //     
    //     let last = files.length
    //     
    //     let temp = [...files]
    //     
    //     temp.push(last)
    //     
    //     setFiles(temp)
    // }

    const navigateToHome = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/");
    };

    const validationSchema = Yup.object({
        militaryStatus: Yup.string().trim().required("Military Status is required"),
    });

    const sendData = (values, actions) => {
        var formdata = new FormData();
        formdata.append("cv", myResume);
        formdata.append("militaryStatus", values.militaryStatus);

        var requestOptions = {
            method: "POST",
            body: formdata,
            redirect: "follow",
        };

        fetch(`${API_URL}/externals`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                
                formik.resetForm();
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Great...",
                    text: `${result.message}`,
                    showConfirmButton: false,
                    timer: 3500,
                }).then(() => navigateToHome());
            })
            .catch((error) => console.error(error));
        // Swal.fire({
        //   position: "center",
        //   icon: "success",
        //   title: "Your message has been sent successfully",
        //   showConfirmButton: false,
        //   timer: 2500,
        // });
        // formik.resetForm();
    };

    const formik = useFormik({
        initialValues: {
            militaryStatus: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            // setLoading(true);
            sendData(values, actions);
        },
    });

    const handleChangeMilitaryStatus = (event) => {
        setMilitaryStatus(event.target.value);
        formik.values.militaryStatus = event.target.value;
    };

    function fetchToken() {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
        var urlencoded = new URLSearchParams();
        urlencoded.append("client_id", "nlEwVuIjIpRzhaTgorxalVxoTQq0x18wZUtWpPlH");
        urlencoded.append(
            "client_secret",
            "vSiSyCh7COOcpj4kZKuQgSh1aQH8EOm1vjn4wZVmd3E6e85AbidB406Z4nVhbLkuSq8oZWptcGyynwT1Y5X2KhQhDWT76mbh51bVLomPLon6lXAdLMtsgTaoSmrHmBqh"
        );
        urlencoded.append("grant_type", "client_credentials");
        urlencoded.append("token_type", "jwt");

        var requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: urlencoded,
            redirect: "follow",
        };

        fetch(
            "https://experience.eyouthlearning.com/oauth2/access_token/",
            requestOptions
        )
            .then((response) => response.json())
            .catch((error) => console.error(error));
    }

    function FileChange(e) {
        setMyResume(e.target.files[0]);
    }

    useEffect(() => {
        document.title = "Hiring Portal - Externals";
        fetchToken();
    }, []);
    return (
        <>
            {/* <button onClick={addeElement}>test</button> */}
            <Container>
                {files && files.map((e, i) => <Box
                    sx={{
                        py: 2,
                        my: 2,
                        backgroundColor: "#FCFBFF",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                        inset: "4px 4px 7px rgba(0, 0, 0, 0.25)",
                        borderRadius: '25px',
                        minWidth: "300px"
                    }}>
                    <Container>
                        <Typography
                            // variant="h2"
                            component="div"
                            sx={{
                                fontSize: "1.5rem",
                                fontWeight: 500,
                                fontFamily: { theme },
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center"
                            }}
                        //   className="typography-h2"
                        >
                            <span style={{ color: "#283B91" }}>File {i + 1}</span>

                            {/* <IconButton
                        onClick={(e) => deleteElement(e, i)} 
                        >
                            <RemoveCircleOutlineIcon id={i + 1} sx={{ color: "red" }} />
                        </IconButton> */}
                        </Typography>

                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Grid item className="form">
                                <Box
                                    component="form"
                                    noValidate
                                    onSubmit={formik.handleSubmit}
                                >
                                    <Box
                                        sx={{
                                            maxWidth: "100%",
                                            mb: 2,
                                            mt: 2,
                                        }}
                                    >
                                        <FormControl fullWidth>
                                            <Select
                                                size='small'
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={formik.values.militaryStatus}
                                                label="Military Status"
                                                onChange={handleChangeMilitaryStatus}
                                                sx={{ backgroundColor: "white" }}
                                            >
                                                <MenuItem value={"CV"}>CV</MenuItem>
                                                <MenuItem value={"Cover-Letter"}>
                                                    Cover Letter
                                                </MenuItem>
                                                <MenuItem value={"Other"}>Other</MenuItem>
                                            </Select>
                                        </FormControl>
                                        {formik.touched.militaryStatus &&
                                            formik.errors.militaryStatus && (
                                                <small className="text-danger">
                                                    {formik.errors.militaryStatus}
                                                </small>
                                            )}
                                    </Box>

                                    <Stack direction="row" alignItems="center" spacing={4}>
                                        <input
                                            accept=".pdf"
                                            type="file"
                                            id="myCV"
                                            onChange={FileChange}
                                        />
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            sx={{ backgroundColor: "#225ee4" }}
                                        >
                                            Submit
                                        </Button>
                                    </Stack>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box >)}
            </Container>

        </>
    );
}

export default Attachment