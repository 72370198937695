import { Typography } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import CircularProgress from "@mui/material/CircularProgress";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import Box from "@mui/material/Box";
import { Outlet } from "react-router-dom";
import styles from "./Dashboard.module.css";
import { useNavigate } from "react-router-dom";


function Dashboard() {
  const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categoryReducer);
  const tokenState = useSelector((state) => state.tokenReducer);
  // 

  const [focus, setFocus] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const handleInput = (e) => {
    // 
    setSearch(e.target.value);
  };

  // const toFetchToken = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Cookie",
  //     "csrftoken=N94GwAvoRgvO8kxC2nQVtI0x3t8iDBWwjS4W0439ZzV3gUjwYoG3Ti8uZ5ZI3q8d; openedx-language-preference=en; sessionid=1|ecsicliqgug4u3usghuvmiui15y4s5yj|533DFxQLxrIl|Ijk4Nzc4MjVjN2EyNThkZDAxZWQwMGU0MzQ3Mjc4OTRlYTFhMmU4NzhlMDUyNWE4ZWNiNDFkNDM2NGM5YTk5ZTci:1oaa1j:vr1GT0y3gnBvEeftdAlsOP0-bsM"
  //   );

  //   var formdata = new FormData();
  //   formdata.append("client_id", "login-service-client-id");
  //   formdata.append("grant_type", "password");
  //   formdata.append("token_type", "jwt");
  //   formdata.append("username", "TechAdmin");
  //   formdata.append("password", "eyouthadmin12345");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://experience.eyouthlearning.com/oauth2/access_token/",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // 
  //       // setToken(result.access_token);
  //       dispatch(changeToken(result.access_token));
  //       // 
  //     })
  //     .catch((error) => );
  // };

  // useEffect(() => {
  //   document.title = "Hiring Portal - Dashboard";
  //   if (state.loggedUserReducer.token === undefined) navigate("/signin");
  //   // toFetchToken();
  // }, []);

  // useEffect(() => {
  //   
  // }, [categoryValue]);
  // useEffect(() => {
  //   
  // }, [category]);

  return (
    <Box container sx={{ minHeight: "100vh", display:"flex", flexDirection:"row"}}>
      <Box
          sx={{width:"80%", display:"flex"}}
          >
            <Box sx={{display:"flex", flexDirection:"row", width:"100%"}}>
              <div defaultValue={0} className={styles.tabs}>
                <div className={styles.tabsList}>
                  <Link className={styles.tab} to="/dashboard/hiring-partners-requests">Hiring Partners Requests</Link>
                  <Link className={styles.tab} to="/dashboard/vacancies">Vacancies</Link>
                  <Link className={styles.tab} to="/dashboard/partners-vacancies">Partners' Vacancies</Link>
                </div>
              </div>
              <div className={styles.tabPanel}>
                <Outlet />
              </div>
            </Box>      
      </Box>
      <Box sx={{width:"20%"}}>
        <Box sx={{display:"flex", flexDirection:"column"}}>
          <Box sx={{border: "0.590053px solid #EAEEF4", display:"flex", flexDirection:"column"}} py={2} px={4}>
            <Typography color="#263997" 
              sx={{fontWeight: "600", fontSize: "18px"}}
            >
              Completed -Vacancies
            </Typography>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
              <Typography color="#F0940A" sx={{fontWeight: "600",
              fontSize: "28.3226px"}}>500</Typography>
              <Box sx={{background: "linear-gradient(180deg, rgba(75, 93, 191, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
              borderRadius: "74.9475px", width: "50px", height: "50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img width={30} height={30}src={require("../images/book-check.svg")} />
              </Box>
            </Box>
          </Box>
          <Box sx={{border: "0.590053px solid #EAEEF4", display:"flex", flexDirection:"column"}} py={2} px={4}>
            <Typography color="#263997" 
              sx={{fontWeight: "600", fontSize: "18px"}}
            >
              Users -Hired
            </Typography>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
              <Typography color="#F0940A" sx={{fontWeight: "600",
              fontSize: "28.3226px"}}>500</Typography>
              <Box sx={{background: "linear-gradient(180deg, rgba(75, 93, 191, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
              borderRadius: "74.9475px", width: "50px", height: "50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <PeopleAltIcon sx={{color:"#263997", width:"30px", height:"30px"}}/>
              </Box>
            </Box>
          </Box>
          <Box sx={{border: "0.590053px solid #EAEEF4", display:"flex", flexDirection:"column"}} py={2} px={4}>
            <Typography color="#263997" 
              sx={{fontWeight: "600", fontSize: "18px"}}
            >
              User -Registered
            </Typography>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
              <Typography color="#F0940A" sx={{fontWeight: "600",
              fontSize: "28.3226px"}}>500</Typography>
              <Box sx={{background: "linear-gradient(180deg, rgba(75, 93, 191, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
              borderRadius: "74.9475px", width: "50px", height: "50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img width={30} height={30} src={require("../images/grad.svg")} />
              </Box>
            </Box>
          </Box>
          <Box sx={{border: "0.590053px solid #EAEEF4", display:"flex", flexDirection:"column"}} py={2} px={4}>
            <Typography color="#263997" 
              sx={{fontWeight: "600", fontSize: "18px"}}
            >
              HP -Registered
            </Typography>
            <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
              <Typography color="#F0940A" sx={{fontWeight: "600",
              fontSize: "28.3226px"}}>500</Typography>
              <Box sx={{background: "linear-gradient(180deg, rgba(75, 93, 191, 0.5) 0%, rgba(255, 255, 255, 0) 100%)",
              borderRadius: "74.9475px", width: "50px", height: "50px", display:"flex", justifyContent:"center", alignItems:"center"}}>
                <img width={30} height={30} src={require("../images/formal-person.svg")} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Dashboard;
