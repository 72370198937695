import React, { useEffect, useState } from 'react';
import styles from './ProfessionalInfo.module.css';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';

const LanguagesComponent = () => {
  const [languages, setLanguages] = useState([]);
  const token = getToken();

  useEffect(() => {
    getUserProfile(token)
      .then((res) => {
        if (res && res.applicant_profile && Array.isArray(res.applicant_profile.languages)) {
          setLanguages(res.applicant_profile.languages);
        } else {
          setLanguages([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("Failed to fetch languages data:", error);
        setLanguages([]); // Fallback to an empty array in case of an error
      });
  }, [token]);

  return (
    <div className='Languages'>
      <h6 className={styles.textProfessional}>Languages</h6>
      <ul>
        {languages.map((language, index) => (
          <div key={index}>
            <li className={styles.languageColor}>{language.language}</li>
            <li>{language.grade}</li>
          </div>
        ))}
      </ul>
    </div>
  );
};

export default LanguagesComponent;
