import React from "react";
import { Avatar, Box, Button, List, ListItem, Typography } from "@mui/material";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import SchoolIcon from '@mui/icons-material/School';

const Talents = (props) => {

    return (
        <>
        {
            [...Array(5)].map((e, index)=>(
                <Box key={index} px={4} py={3} mb={2} sx={{
                    background: "rgba(254, 239, 221, 0.35)",
                    borderBottom: "1.73262px solid #CDCDCD",
                    display:"flex",
                    flexDirection:"row",
                    justifyContent: 'space-between'
                    }}>
                        <Box sx={{display:"flex", flexDirection:"row"}}>
                            <Avatar sx={{ bgcolor: "rgba(240, 148, 10, 0.6)" }}>N</Avatar>
                            <Box sx={{display:"flex", flexDirection:"column"}}>
                                <Typography variant="h4" color="#283B91">Sara Ahmed</Typography>
                                <Typography variant="h5" color="#283B91">Product Manager at 
                                    <span style={{color:"#F0940A"}}>{" "}LinkedIn</span>
                                </Typography>
                                <List>
                                    <ListItem sx={{display:"flex", flexDirection:"row"}}>
                                        <LocationOnOutlinedIcon sx={{color:"#283B91", mr:1}}/>
                                        <Typography>Cairo,Egypt</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:"flex", flexDirection:"row"}}>
                                        <SchoolIcon sx={{color:"#283B91", mr:1}}/>
                                        <Typography>Faculty of Computing and Information</Typography>
                                    </ListItem>
                                    <ListItem sx={{display:"flex", flexDirection:"row"}}>
                                        <img src={require("../images/promote.svg")} width="20px" height="20px" style={{marginRight:"8px"}} />
                                        <Typography>(0-3) Years</Typography>
                                    </ListItem>
                                </List>
                            </Box>
                        </Box>
                        <Box>
                            <Button variant="contained" endIcon={<ArrowDropDownOutlinedIcon />}>Actions</Button>
                        </Box>
                </Box>
            ))
        }
        </>
    )
}
export default Talents;