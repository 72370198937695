import FilterListIcon from '@mui/icons-material/FilterList'
import { Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"

const companyOptions = [
    {name: "company name", value: "company val"},
    {name: "company name", value: "company val"},
    {name: "company name", value: "company val"},
    {name: "company name", value: "company val"}
]
const CompanyFilter = () =>{
    return (
        <Box sx={{border: "0.411782px solid #EAEEF4",
        boxShadow: "0px 2.79149px 2.79149px rgba(0, 0, 0, 0.25)",
        display:"flex", 
        flexDirection:"row",
        justifyContent:"center",
        alignItems:"center",
        textTransform:"capitalize"}}>
            <label>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                    <FilterListIcon sx={{color:"#EB7A12"}}/>
                    <Typography color="#263997">Choose Company</Typography>
                </Box>
            </label>
            <select name="company" value={1} onChange={()=>{}} style={{
                    display: "inline-block", background:"transparent",border:"0px", padding:"0px"
                    }}>
            <option defaultValue value="" style={{}}>
                {" "}
            </option>
            {companyOptions.map((company, index) => {
                    return (
                        <option key={parseInt(index.toString() + company.value.toString())} value={company.value} style={{  }}>
                            {company.name}
                        </option>
                    );
                })}
            </select>
            </Box>
    )
}
export default CompanyFilter