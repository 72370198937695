import {
    Avatar,
    Button,
    Card,
    CardContent,
    FormControlLabel,
    Grid,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Typography,
    Box,
    Container,
    FormControl,
    TextField
} from "@mui/material";
import { indigo } from '@mui/material/colors';
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { API_URL } from "../../config";
import { Cities } from './Cities';
import { Countries } from './Countries';
import styles from "./GeneralInfo.module.css";
import { MartialConsts } from './MartialStatus';
import { MilitaryConsts } from './MilitaryStatus';

const theme = createTheme({
    typography: {
        fontFamily: [
            "Open Sans",
            "Helvetica Neue",
            "Helvetica",
            "Arial",
            "sans-serif",
        ].join(","),
    },
});

function GeneralInfo() {
    const [loading, setLoading] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageSizeError, setImageSizeError] = useState(false);
    const [imageData, setImageData] = useState(null);
    const navigate = useNavigate();

    const navigateToHome = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        navigate("/");
    };

    const validationSchema = Yup.object({
        fullName: Yup.string()
            // .trim()
            .required("Name is required")
            .min(3, "Minimum chars are 3"),
        birthDate: Yup.string().trim().required("Date of birth is required"),
        gender: Yup.string().trim().required("Gender is required"),
        nationality: Yup.string()
            .trim()
            .required("Nationality is required")
            .min(3, "Minimum chars are 3"),
        militaryStatus: Yup.string().trim().required("Military Status is required"),
        martialStatus: Yup.string().trim().required("Martial Status is required"),
        country: Yup.string().trim().required("Country is required"),
        city: Yup.string().trim().required("City is required"),
        address: Yup.string().trim().required("Address is required"),
        emailAddress: Yup.string()
            .trim()
            .email("Enter a valid email address")
            .required("Email is required"),
        phoneOne: Yup.number("Only number").positive("Only positive number").integer("Only integer number").required("Phone is required"),
        phoneTwo: Yup.number("Only number").positive("Only positive number").integer("Only integer number")
    });



    const formik = useFormik({
        initialValues: {
            img: {},
            fullName: "",
            birthDate: "",
            gender: "",
            nationality: "",
            militaryStatus: "",
            martialStatus: "",
            country: "",
            city: "",
            address: "",
            emailAddress: "",
            phoneOne: "",
            phoneTwo: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values, actions) => {
            
            // sendData(values, actions);
        },
    });

    const handleChangeImage = (event) => {
        setImageSizeError(false);
        setSelectedImage(null);
        setImageData(null);
        if (event.target.files && event.target.files[0].size > 2000000) {
            setImageSizeError(true);
        } else {
            setSelectedImage(URL.createObjectURL(event.target.files[0]));
            setImageData(event.target.files[0]);
            formik.setFieldValue("img", event.target.files[0]);
        }
    };

    const handleChange = (field) => (event) => {
        formik.setFieldValue(field, event.target.value);
        
    };

    useEffect(() => {
        document.title = "Hiring Portal - Externals";
    }, []);

    return (
        <Box>
            <Container>
                <Grid
                    container
                    direction="column"
                    justifyContent="center"
                    color="#263997"
                    my={1}
                >
                    <div>
                        <Card sx={{ display: "flex", alignItems: "center", justifyContent: "left", px: 1, color: "#263997" }}>
                            <Avatar
                                src={selectedImage}
                                alt="user.fullName"
                                sx={{ width: 96, height: 96, justifySelf: "flex-start", bgcolor: indigo[900] }}
                            />
                            <CardContent>
                                <Typography variant="h5" component="div">
                                    {formik.values.fullName || "User Name"}
                                </Typography>
                                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                                    only .jpg, .png, or .gif photo with max size of 2MB
                                </Typography>
                                <label htmlFor="uploadImage" className={styles.label}>
                                    Choose photo
                                </label>
                                <input type="file" id="uploadImage" name="myImage" accept="image/*" onChange={handleChangeImage} />
                                {imageSizeError && (
                                    <small className="d-block text-danger">maximum size is 2Mb</small>
                                )}
                            </CardContent>
                        </Card>
                    </div>
                    <Grid item className="form">
                        <Typography
                            component="div"
                            gutterBottom
                            sx={{
                                fontSize: "1.8rem",
                                fontWeight: 500,
                                fontFamily: theme.typography.fontFamily,
                            }}
                        >
                            General Info
                        </Typography>
                        <Box
                            component="form"
                            noValidate
                            onSubmit={formik.handleSubmit}
                            sx={{ mt: 1, position: "relative" }}
                        >
                            <label htmlFor="fullName">
                                <strong>Full Name</strong>
                            </label>
                            <TextField
                                sx={{ mb: 1 }}
                                placeholder="Full Name"
                                // value={formik.values.fullName}
                                size="small"
                                fullWidth
                                id="fullName"
                                name="fullName"
                                // autoComplete="fullName"
                                onChange={formik.handleChange}
                            // error={formik.touched.fullName && Boolean(formik.errors.fullName)}
                            />
                            {/* {formik.touched.fullName && formik.errors.fullName && (
                                <small className="text-danger">{formik.errors.fullName}</small>
                            )} */}
                            <br />
                            <label htmlFor="birthDate">
                                <strong>Birth Date</strong>
                            </label>
                            <TextField
                                sx={{ mb: 1 }}
                                size="small"
                                type="date"
                                value={formik.values.birthDate}
                                fullWidth
                                id="birthDate"
                                name="birthDate"
                                autoComplete="birthDate"
                                onChange={formik.handleChange}
                                error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                            />
                            {formik.touched.birthDate && formik.errors.birthDate && (
                                <small className="text-danger">{formik.errors.birthDate}</small>
                            )}
                            <br />
                            <div className={styles.handleViewMobile}>
                                <FormControl>
                                    <label htmlFor="gender">
                                        <strong>Gender</strong>
                                    </label>
                                    <RadioGroup
                                        row
                                        id="gender"
                                        aria-labelledby="demo-row-radio-buttons-group-label"
                                        name="row-radio-buttons-group"
                                        size="small"
                                        value={formik.values.gender}
                                        onChange={formik.handleChange}
                                    >
                                        <FormControlLabel
                                            value="male"
                                            control={<Radio />}
                                            label="Male"
                                        />
                                        <FormControlLabel
                                            value="female"
                                            control={<Radio />}
                                            label="Female"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                {formik.touched.gender && formik.errors.gender && (
                                    <small className="text-danger">{formik.errors.gender}</small>
                                )}
                                <br />
                                <label htmlFor="nationality">
                                    <strong>Nationality</strong>
                                </label>
                                <TextField
                                    sx={{ mb: 1 }}
                                    size="small"
                                    placeholder="Nationality"
                                    value={formik.values.nationality}
                                    fullWidth
                                    id="nationality"
                                    name="nationality"
                                    autoComplete="nationality"
                                    onChange={formik.handleChange}
                                    error={formik.touched.nationality && Boolean(formik.errors.nationality)}
                                />
                                {formik.touched.nationality && formik.errors.nationality && (
                                    <small className="text-danger">{formik.errors.nationality}</small>
                                )}
                                <br />
                                <label htmlFor="militaryStatus">
                                    <strong>Military Status</strong>
                                </label>
                                <Select
                                    sx={{ mb: 1 }}
                                    size="small"
                                    value={formik.values.militaryStatus}
                                    displayEmpty
                                    fullWidth
                                    onChange={handleChange('militaryStatus')}
                                    error={formik.touched.militaryStatus && Boolean(formik.errors.militaryStatus)}
                                >
                                    {MilitaryConsts.map((mil, i) => (
                                        <MenuItem value={mil} key={i}>
                                            {mil}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.militaryStatus && formik.errors.militaryStatus && (
                                    <small className="text-danger">{formik.errors.militaryStatus}</small>
                                )}
                                <br />
                                <label htmlFor="martialStatus">
                                    <strong>Martial Status</strong>
                                </label>
                                <Select
                                    sx={{ mb: 1 }}
                                    size="small"
                                    value={formik.values.martialStatus}
                                    displayEmpty
                                    fullWidth
                                    onChange={handleChange('martialStatus')}
                                    error={formik.touched.martialStatus && Boolean(formik.errors.martialStatus)}
                                >
                                    {MartialConsts.map((mar, i) => (
                                        <MenuItem value={mar} key={i}>
                                            {mar}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.martialStatus && formik.errors.martialStatus && (
                                    <small className="text-danger">{formik.errors.martialStatus}</small>
                                )}
                                <br />
                                <label htmlFor="country">
                                    <strong>Country</strong>
                                </label>
                                <Select
                                    sx={{ mb: 1 }}
                                    size="small"
                                    value={formik.values.country}
                                    displayEmpty
                                    fullWidth
                                    onChange={handleChange('country')}
                                    error={formik.touched.country && Boolean(formik.errors.country)}
                                >
                                    {Countries.map((country, i) => (
                                        <MenuItem value={country.name} key={i}>
                                            {country.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.country && formik.errors.country && (
                                    <small className="text-danger">{formik.errors.country}</small>
                                )}
                                <br />
                                <label htmlFor="city">
                                    <strong>City</strong>
                                </label>
                                <Select
                                    sx={{ mb: 1 }}
                                    size="small"
                                    value={formik.values.city}
                                    displayEmpty
                                    fullWidth
                                    onChange={handleChange('city')}
                                    error={formik.touched.city && Boolean(formik.errors.city)}
                                >
                                    {Cities.map((city, i) => (
                                        <MenuItem value={city.name} key={i}>
                                            {city.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.city && formik.errors.city && (
                                    <small className="text-danger">{formik.errors.city}</small>
                                )}
                                <br />
                                <label htmlFor="address">
                                    <strong>Address</strong>
                                </label>
                                <TextField
                                    sx={{ mb: 1 }}
                                    size="small"
                                    placeholder="Address"
                                    value={formik.values.address}
                                    fullWidth
                                    id="address"
                                    name="address"
                                    autoComplete="address"
                                    onChange={formik.handleChange}
                                    error={formik.touched.address && Boolean(formik.errors.address)}
                                />
                                {formik.touched.address && formik.errors.address && (
                                    <small className="text-danger">{formik.errors.address}</small>
                                )}
                                <br />
                                <label htmlFor="emailAddress">
                                    <strong>Email</strong>
                                </label>
                                <TextField
                                    sx={{ mb: 1 }}
                                    size="small"
                                    placeholder="Email Address"
                                    value={formik.values.emailAddress}
                                    fullWidth
                                    id="emailAddress"
                                    name="emailAddress"
                                    autoComplete="emailAddress"
                                    onChange={formik.handleChange}
                                    error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                                />
                                {formik.touched.emailAddress && formik.errors.emailAddress && (
                                    <small className="text-danger">{formik.errors.emailAddress}</small>
                                )}
                                <br />
                                <label htmlFor="phoneOne">
                                    <strong>Phone Number One</strong>
                                </label>
                                <TextField
                                    sx={{ mb: 1 }}
                                    size="small"
                                    placeholder="Phone Number One"
                                    value={formik.values.phoneOne}
                                    fullWidth
                                    id="phoneOne"
                                    name="phoneOne"
                                    autoComplete="phoneOne"
                                    onChange={formik.handleChange}
                                    error={formik.touched.phoneOne && Boolean(formik.errors.phoneOne)}
                                />
                                {formik.touched.phoneOne && formik.errors.phoneOne && (
                                    <small className="text-danger">{formik.errors.phoneOne}</small>
                                )}
                                <br />
                                <label htmlFor="phoneTwo">
                                    <strong>Phone Number Two</strong>
                                </label>
                                <TextField
                                    sx={{ mb: 1 }}
                                    size="small"
                                    placeholder="Phone Number Two"
                                    value={formik.values.phoneTwo}
                                    fullWidth
                                    id="phoneTwo"
                                    name="phoneTwo"
                                    autoComplete="phoneTwo"
                                    onChange={formik.handleChange}
                                    error={formik.touched.phoneTwo && Boolean(formik.errors.phoneTwo)}
                                />
                                {formik.touched.phoneTwo && formik.errors.phoneTwo && (
                                    <small className="text-danger">{formik.errors.phoneTwo}</small>
                                )}
                                <br />
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    disabled={loading}
                                    sx={{ mt: 3, mb: 2 }}
                                >
                                    Submit
                                </Button>
                            </div>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}

export default GeneralInfo;
