import EmailIcon from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
// import PersonalInformation from './PersonalInformation/PersonalInformation';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { getUserProfileComapnyPreview } from '../../Middleware/CandidateApis';
import { WEBSITE_URL, getToken } from '../../Middleware/Constans';
import styles from './UserProfileCompanyPreview.module.css';
// import AppliedJobs from './AppliedJobs/AppliedJobs';
// import Attachments from './Attachments/Attachments';
// import EYouthCertification from './EYouthCertification/EYouthCertification';
// import Points from './Points/Points';
// import ProfessionalInfo from './ProfessionalInfo/ProfessionalInfo';
import { useNavigate, useParams } from 'react-router-dom';
import PersonalInformation from '../UserProfile/PersonalInformation/PersonalInformation';
// import ProfessionalInfo from '../../components/ProfessionalInfo/ProfessionalInfo';
import Attachments from '../UserProfile/Attachments/Attachments';
import ProfessionalInfo from '../UserProfile/ProfessionalInfo/ProfessionalInfo';
import defultImage from '../../images/assets/logo.jpg';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[200],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: 'var(--main_color)',
  },
  transform: 'rotate(180deg)',
}));

function UserProfileCompanyPreview() {
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState({});
  const [userOtherData, setUserOtherData] = useState({});
  const token = getToken();
  const navigate = useNavigate();

  const { username } = useParams();
  // 

  const handleEditProfile = () => {
    navigate("/user-profile/edit-profile");
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getUserProfileComapnyPreview(token, username).then((res) => {
      const profileData = res.applicant_profile;
      setProfile(profileData);
      console.log('profileData', res);
      // 
      setUserOtherData(res);
    }).catch((error) => {
      console.error(error);
    });
  }, []);

  return (
    <div className={styles.mainContainerProfile}>
      <div className={`d-flex ${styles.DetailsAndButton}`}>

      </div>

      <div className={`d-flex w-100 m-auto justify-content-around ${styles.mobileViewContent}`}>
        <aside className={styles.AsideContainer}>
          <div className='d-flex align-items-center border-bottom my-2'>
            <img src={profile.profile_image ? `${WEBSITE_URL}${profile.profile_image}` : defultImage} alt="" className={`my-2 ${styles.userProfileImage}`} />
            <div className={styles.userProfileInfo}>
              <h2 className='p-0 m-0'>{profile.fullname} </h2>
              <h5 className='p-0 m-0'>{profile.job_title} </h5>
            </div>
          </div>
          <h2 className={styles.contactH1}>Contact</h2>
          <div className='d-flex mb-3'>
            <EmailIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <span>Email</span>
              <span>{userOtherData.email}</span>
            </div>
          </div>
          <div className='d-flex mb-3'>
            <LocalPhoneIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <span>Phone</span>
              <span>{profile.phonenumber}</span>
            </div>
          </div>
          {profile.linkedin_link && <div className='d-flex mb-3'>
            <LinkedInIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.linkedin_link}> <span>Linkedin</span> </a>
            </div>
          </div>}
          {profile.instgram_link && <div className='d-flex mb-3'>
            <InstagramIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.instgram_link}> <span>Instagram</span></a>
            </div>
          </div>}
          {profile.facebook_link && <div className='d-flex mb-3'>
            <FacebookRoundedIcon className='me-2' sx={{ color: "var(--main_color)" }} />
            <div className='d-flex flex-column'>
              <a href={profile.facebook_link}> <span >Facebook</span></a>
            </div>
          </div>}
          {/* <Points />   */}
        </aside>

        <main className={styles.mainContainer}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Personal Info" className={styles.PersonalInfo} {...a11yProps(0)} />
                <Tab label="Professional Info" className={styles.PersonalInfo} {...a11yProps(1)} />
                <Tab label="CV & Attachments" className={styles.PersonalInfo} {...a11yProps(2)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <PersonalInformation FullName={profile.fullname} dateOfBirth={profile.date_birth}
                street={profile.street} country={profile.country} city={profile.city} gender={profile.gender}
                military_status={profile.military_status} nationality={profile.nationality} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <ProfessionalInfo About_me={profile.about} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <Attachments />
            </CustomTabPanel>
          </Box>
        </main>
      </div>
    </div>
  );
}

export default UserProfileCompanyPreview;
