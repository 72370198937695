export const MilitaryConsts = [
    "Select military status",
    "Exempted",
    "Completed",
    "Postponed"
]







