import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from "./ExperienceSection.module.css";

export default function ExperienceSection() {
    // experience list hundle
    const [inputList, setInputList] = useState([{ title: "", companyName: "", start: "", end: "", currently: "", roles: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { title: "", companyName: "", start: "", end: "", currently: "", roles: "" }]);
    };
    return (
        <div>
            <h2 className={styles.text_h1}><AddCircleIcon onClick={handleAddClick} />Add an experience</h2>
            {inputList.map((x, i) => {
                return (
                    <div className={styles.div_shadow}>
                        <div>
                            <h2 className={styles.text_h1}>Job experience {i + 1} </h2>
                            <div className='d-flex justify-content-end '><RemoveCircleOutlineIcon className={styles.remove} onClick={() => handleRemoveClick(i)} /></div>
                            < div className='d-flex w-100 justify-content-between my-2'>
                                <div className={styles.input_field}>
                                    <label>Title/Position</label>
                                    <input
                                        name="title"
                                        placeholder="title"
                                        value={x.title}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>Company Name</label>
                                    <input
                                        name="companyName"
                                        placeholder="company Name"
                                        value={x.companyName}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex my-2 w-100 justify-content-between'>
                                <div className={styles.input_field}>
                                    <label>Strat Date</label>
                                    <input
                                        type="date"
                                        name="start"
                                        placeholder="start"
                                        value={x.start}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>End Date</label>
                                    <input
                                        type="date"
                                        name="end"
                                        placeholder="end"
                                        value={x.end}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex w-100 justify-content-end me-3'>
                                <input
                                    type="checkbox"
                                    value={x.currently}
                                    onChange={e => handleInputChange(e, i)}
                                />
                                <label className='ms-2'>Currently Work There</label>
                            </div>
                            <div className='d-flex flex-column w-75'>
                                <label>Roles & Responsbilites</label>
                                <textarea
                                    name="roles"
                                    placeholder="roles"
                                    value={x.roles}
                                    className="form-control w-75"
                                    onChange={e => handleInputChange(e, i)}
                                />
                            </div>
                        </div>
                    </div>

                );
            })}
        </div>
    )


}
