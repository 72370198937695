import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from "./RefSection.module.css";

export default function RefSection() {
    // experience list hundle
    const [inputList, setInputList] = useState([{ name: "", title: "", Email: "", Phone: "", Relationship: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { name: "", title: "", Email: "", Phone: "", Relationship: "" }]);
    };
    return (
        <div>
            <h2 className={styles.text_h1}><AddCircleIcon onClick={handleAddClick} />Add References</h2>
            {inputList.map((x, i) => {
                return (
                    <div className={styles.div_shadow}>
                        <div>
                            {/* {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>} */}
                            <h2 className={styles.text_h1}>Reference {i + 1}</h2>
                            <div className='d-flex justify-content-end '><RemoveCircleOutlineIcon className={styles.remove} onClick={() => handleRemoveClick(i)} /></div>
                            < div className='d-flex w-100 justify-content-between my-2'>
                                <div className={styles.input_field}>
                                    <label>Name</label>
                                    <input
                                        name="name"
                                        placeholder="name"
                                        value={x.name}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>Title</label>
                                    <input
                                        name="title"
                                        placeholder="title"
                                        value={x.title}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex my-2 w-100 justify-content-between'>
                                <div className={styles.input_field}>
                                    <label>Email</label>
                                    <input
                                        name="Email"
                                        placeholder="Email"
                                        value={x.Email}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>Phone</label>
                                    <input
                                        name="Phone"
                                        placeholder="Phone"
                                        value={x.Phone}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column w-75'>
                                <label>Relationship</label>
                                <input
                                    name="Relationship"
                                    placeholder="Mentor,manger,etc...."
                                    value={x.Relationship}
                                    className="form-control w-75"
                                    onChange={e => handleInputChange(e, i)}
                                />
                            </div>
                        </div>
                    </div>

                );
            })}
        </div>
    )


}
