import { useFormik } from 'formik';
import React from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import * as yup from "yup";
import styles from "./Experience.module.css";
import ExperienceSection from './ExperienceSection/ExperienceSection';
import RefSection from './RefSection/RefSection';


function Experience() {
    // select
    const options = [
        { value: 'less than on year', label: 'less than on year' },
        { value: 'year', label: 'year' },
        { value: '1-5', label: '1-5' }
    ]
    const Categories = [
        { value: 'web development', label: 'web development' },
        { value: 'development', label: 'development' },
        { value: 'web', label: 'web' }

    ]
    const animatedComponents = makeAnimated();
    // end 

    const validationSchema = yup.object({
        yearsOfExperience: yup.string(),
        Categories: yup.string(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: { username: "", password: "" },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            

        },
    });
    return (
        <>
            <div className='container'>
                <div className={styles.experience_div}>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='my-3'>
                            <h2 className={styles.text_h1}>How many years of experience do you have?</h2>
                            <Select options={options} />

                        </div>
                        <div className='my-3'>
                            <h2 className={styles.text_h1}>Categories you are interested in?</h2>
                            <Select
                                closeMenuOnSelect={false}
                                components={animatedComponents}
                                isMulti
                                options={Categories}
                            />
                        </div>
                        {/* Experience */}
                        <ExperienceSection />
                        <RefSection />
                        <div className='d-flex w-100 justify-content-end'>
                            <button className={styles.btn}> Save && Countine </button>
                        </div>
                    </form>
                </div>
            </div>
        </>

    )
}

export default Experience