import React from 'react';
import './Review.css';
import userimage from '../../images/Ellipse 2.png'
import ReviewEducation from './ReviewEducation/ReviewEducation';
import ReviewExperience from './ReviewExperience/ReviewExperience';
import ReviewOther from './ReviewOther/ReviewOther';




function Review() {
    const Swal = require('sweetalert2');



    return (
        <>
            <div className='container my-5 m-auto'>
                <div className='row first-div '>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>General Info</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Location</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Contact Info </h3>
                    </div>
                </div>
                <div>
                    <div className='row' style={{ width: "90%", background: "#FEEFDD", borderRadius: "16px", marginTop: "1px" }}>
                        <div className='col-4 d-flex justify-content-center'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <img src={userimage} />
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>Name:</span> Sara Ahmed</p>
                                                <p><span className='text-our-color'>Birthdate :</span> 15/5/2005</p>
                                                <p><span className='text-our-color'>Gender :</span>female</p>
                                                <p><span className='text-our-color'>Nationality :</span>Egyptian</p>
                                                <p><span className='text-our-color'>Marital status :</span>Single</p>
                                                <p><span className='text-our-color'>Military status :</span>Not applicable</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br"></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 d-flex justify-content-center'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr tl"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>Country :</span> Egypt</p>
                                                <p><span className='text-our-color'>City :</span> Cairo</p>
                                                <p><span className='text-our-color'>Address :</span>Location of dummy, address of dummy, location map,</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br bl"></div>
                                    {/* <div class="bevel bl br"></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-4 d-flex justify-content-center'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tl"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>LinkedIn :</span> https://www.linkedin.com/feed/</p>
                                                <p><span className='text-our-color'>Email :</span> Sara@gmail.com</p>
                                                <p><span className='text-our-color'>phone 1 :</span>+20101000000</p>
                                                <p><span className='text-our-color'>phone 2 :</span>+20101000000</p>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel bl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ReviewExperience />
                <ReviewEducation />
                <ReviewOther />
                <div className='d-flex justify-content-end' style={{ width: "80%" }}>
                    <button className='btn btn-primary' onClick={() => {
                        Swal.fire(
                            'Good job!',
                            'Your profile is updated and submitted successfully.',
                            'success'
                        )

                    }}>Submit</button>
                </div>
            </div>


        </>

    )
}

export default Review