import React, { useEffect, useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import logoWhite from "../../images/NavbarLogoEyouth.png";
import { getToken, getUserData } from "../../Middleware/Constans";
import { getComapnyProfile, getUserProfile } from "../../Middleware/CandidateApis";
import styles from "./AppBar.module.css";
import Loading from '../Loading/Loading';

export default function ResponsiveAppBar() {
  const token = getToken();
  const [username, setUsername] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [profileCompleted, setProfileCompleted] = useState(true); // Default to true to prevent initial hiding
  const [loading, setLoading] = useState(true); // State to track loading
  const userData = getUserData();
  const location = useLocation(); // Get the current location
  const navigate = useNavigate();

  useEffect(() => {
    if (userData) {
      setUsername(userData.username);
    }
  }, [userData]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (userData.user_type === "company") {
      getComapnyProfile(userData.company_name).then((res) => {
        if (res && res.company_profile) {
          setProfileCompleted(res.company_profile.completed);
        }
        setLoading(false); // Set loading to false after fetching data
      }).catch(error => {
        console.error("Error fetching company profile:", error);
        setLoading(false); // Set loading to false in case of error
      });
    } else if (userData.user_type === "applicant") {
      getUserProfile(token).then((res) => {
        setProfileCompleted(res.applicant_profile.completed);
        setLoading(false); // Set loading to false after fetching data
      }).catch(error => {
        console.error("Error fetching user profile:", error);
        setLoading(false); // Set loading to false in case of error
      });
    } else {
      setLoading(false); // Set loading to false if not a company or user
    }
  }, [userData]);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    localStorage.removeItem("refresh_token");
    localStorage.removeItem("expired-time");
    window.location.href = "/signin";
  };

  if (loading) {
    return null;
  }

  if ((userData.user_type === "company" || userData.user_type === "applicant") && !profileCompleted) {
    return (
      <>
        <div className={styles.dropdownNew}>
          <button className={styles.buttonRequest} onClick={toggleDropdown}>
            <AccountCircleIcon /> {username} <ArrowDropDownIcon />
          </button>
          {dropdownVisible && (
            <div className={styles.dropdownMenuNew}>
              <a className={styles.dropdownItem} onClick={handleLogout}>
                Logout
              </a>
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <nav className={`${styles.backgroundNavbar} d-flex justify-content-between`}>
      <div className="d-flex align-items-center" onClick={() => navigate("/")}>
        <img className={styles.imageLogo} alt="EYouth-logo" src={logoWhite} />
      </div>
      <button className={styles.menuButton} onClick={toggleMenu}>
        {menuVisible ? <CloseIcon /> : <MenuIcon />}
      </button>

      {menuVisible && (
        <div className={styles.dropdownMenu}>
          <ul className={styles.ulStyles}>
            <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? styles.activeLink : undefined
                }
                end // Ensures exact matching for the root path
              >
                Home
              </NavLink>
            </li>
            {token && <><li>
              <NavLink
                to="/companies"
                className={({ isActive }) =>
                  isActive ? styles.activeLink : undefined
                }
              >
                Companies
              </NavLink>
            </li>
              {userData.user_type !== "company" && (<li>
                <NavLink
                  to="/all-jobs"
                  className={({ isActive }) =>
                    isActive ? styles.activeLink : undefined
                  }
                >
                  Jobs
                </NavLink>
              </li>)}
              {!userData.company_name && (
                <li>
                  <NavLink
                    to="/my-jobs"
                    className={({ isActive }) =>
                      isActive ? styles.activeLink : undefined
                    }
                  >
                    My-Jobs
                  </NavLink>
                </li>
              )}
              {userData.company_name && (
                <>
                  <li>
                    <NavLink
                      to="/my-jobs"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : undefined
                      }
                    >
                      My-Jobs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/hunt"
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : undefined
                      }
                    >
                      Hunt
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to={`/companyProfile/${userData.company_name}/postJob`}
                      className={({ isActive }) =>
                        isActive ? styles.activeLink : undefined
                      }
                    >
                      Post New Job
                    </NavLink>
                  </li>
                </>
              )}
            </>}
            {/* Add buttons here for mobile view */}
            {!token && (
              <>
                <li>
                  <a className={styles.login_button} href="/signin">
                    Sign in
                  </a>
                </li>
                <li>
                  <a className={styles.login_button} href="/signup">
                    Sign up
                  </a>
                </li>
                <li>
                  <a className={styles.buttonRequest} href="/partner-request">
                    Partner Request
                  </a>
                </li>
              </>
            )}
          </ul>
        </div>
      )}

      {token && (
        <ul className={`${styles.ulStyles} ${styles.webMenu}`}>
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? styles.activeLink : undefined
              }
              end // Ensures exact matching for the root path
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/companies"
              className={({ isActive }) =>
                isActive ? styles.activeLink : undefined
              }
            >
              Companies
            </NavLink>
          </li>
          {userData.user_type !== "company" && <li>
            <NavLink
              to="/all-jobs"
              className={({ isActive }) =>
                isActive ? styles.activeLink : undefined
              }
            >
              Jobs
            </NavLink>
          </li>}
          {userData.company_name && (
            <>
              <li>
                <NavLink
                  to="/my-jobs"
                  className={({ isActive }) =>
                    isActive ? styles.activeLink : undefined
                  }
                >
                  My-Jobs
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/hunt"
                  className={({ isActive }) =>
                    isActive ? styles.activeLink : undefined
                  }
                >
                  Hunt
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={`/companyProfile/${userData.company_name}/postJob`}
                  className={({ isActive }) =>
                    isActive ? styles.activeLink : undefined
                  }
                >
                  Post New Job
                </NavLink>
              </li>
            </>
          )}
        </ul>
      )}

      <div className={`${styles.hideOnMobile} `}>
        {!token ? (
          <>
            <a className={styles.login_button} href="/signin">
              Sign in
            </a>
            <a className={styles.login_button} href="/signup">
              Sign up
            </a>
            <a className={styles.buttonRequest} href="/partner-request">
              Partner Request
            </a>
          </>
        ) : (
          <div className={styles.dropdown}>
            <button className={styles.buttonRequest} onClick={toggleDropdown}>
              <AccountCircleIcon /> {username} <ArrowDropDownIcon />
            </button>
            {dropdownVisible && (
              <div className={styles.dropdownMenu}>
                <a
                  href={
                    userData.user_type === "company"
                      ? `/companyProfile/${userData.company_name}`
                      : "/user-profile"
                  }
                  className={styles.dropdownItem}
                >
                  {userData.user_type === "company"
                    ? "My Profile"
                    : "My profile"}
                </a>
                <a className={styles.dropdownItem} onClick={handleLogout}>
                  Logout
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </nav>
  );
}
