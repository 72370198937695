import React, { useEffect, useState } from "react";
import { FormControl, TextField, Button, Alert } from "@mui/material";
import Select from "react-select";
import iconPhoto from "../../images/assets/IconPhoto.svg";
import profileImg from "../../images/assets/ProfilePhoto.svg";
import styles from "./PersonalInfo.module.css";
import { editUserProfile, editUserImage, getUserProfile } from "../../Middleware/CandidateApis";
import { getToken } from "../../Middleware/Constans";
import { useNavigate } from "react-router-dom";
import { getCategories } from "../../Middleware/CompanyApis";

const nationalities = [
    "Algerian", "Bahraini", "Comoran", "Djiboutian", "Egyptian", "Iraqi", "Jordanian", "Kuwaiti", "Lebanese", "Libyan",
    "Mauritanian", "Moroccan", "Omani", "Palestinian", "Qatari", "Saudi", "Somali", "Sudanese", "Syrian", "Tunisian", "Emirati", "Yemeni"
];

const militaryStatuses = ["Active", "Postponed", "N/A"];
const maritalStatuses = ["Single", "Married", "Divorced"];
const countries = [
    "Algeria", "Bahrain", "Comoros", "Djibouti", "Egypt", "Iraq", "Jordan", "Kuwait", "Lebanon", "Libya",
    "Mauritania", "Morocco", "Oman", "Palestine", "Qatar", "Saudi Arabia", "Somalia", "Sudan", "Syria", "Tunisia", "United Arab Emirates", "Yemen"
];

const experienceLevels = ["1-3", "3-5", "5<"];

export const PersonalInfo = () => {
    const [formData, setFormData] = useState({
        name: "",
        job_title: "",
        nation: "",
        date_birth: "",
        gender: "",
        street: "",
        city: "",
        country: "",
        military: "",
        maritalStatus: "",
        image: null,
        work_experience_years: "",
        selectedCategories: [],
    });

    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [isFormComplete, setIsFormComplete] = useState(false);
    const [dateError, setDateError] = useState("");
    const [imageKey, setImageKey] = useState(0);
    const [categories, setCategories] = useState([]);

    const token = getToken();
    const navigate = useNavigate();

    const customStyles = {
        control: (provided) => ({
            ...provided,
            color: "black",
        }),
        singleValue: (provided) => ({
            ...provided,
            color: "black",
        }),
        menu: (provided) => ({
            ...provided,
            color: "black",
        }),
        option: (provided) => ({
            ...provided,
            color: "black",
        }),
    };

    const handleChange = (name, value) => {
        if (name === "date_birth") {
            const selectedDate = new Date(value);
            const currentDate = new Date();

            if (selectedDate > currentDate) {
                setDateError("Date of Birth cannot be in the future");
                return;
            } else {
                setDateError(""); // Clear error if the date_birth is valid
            }
        }

        setFormData((prevFormData) => {
            const newFormData = {
                ...prevFormData,
                [name]: value,
            };

            // Check if all fields are filled
            const isComplete = Object.values(newFormData).every(
                (field) => field !== "" && field !== null
            );
            setIsFormComplete(isComplete);

            return newFormData;
        });
    };

    const handleCategoryChange = (selectedOptions) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            selectedCategories: selectedOptions || [], // Update selected categories
        }));
    };

    const handleImageChange = async (event) => {
        const fileInput = event.target;
        const file = fileInput.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setFormData((prevFormData) => ({
                ...prevFormData,
                image: imageUrl,
            }));

            // Force re-render of the image by updating the key
            setImageKey((prevKey) => prevKey + 1);

            // Upload the image immediately after selection
            try {
                const imageName = file.name;
                await editUserImage(fileInput, imageName, token);
            } catch (error) {
                console.error("Image Upload Error:", error);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        // Ensure the date of birth is in YYYY-MM-DD format
        const formattedDateOfBirth = formData.date_birth
            ? new Date(formData.date_birth).toISOString().split("T")[0]
            : "";

        try {
            const result = await editUserProfile(
                formData.name,
                formData.job_title,
                formData.gender,
                formData.phone,
                formData.nation,
                formData.country,
                formData.city,
                formData.street,
                formData.military,
                formData.maritalStatus,
                formattedDateOfBirth,
                formData.work_experience_years, // Correct key passed to API
                formData.selectedCategories.map(category => category.label),
                token
            );

            if (result.message === "Applicant updated successfully!") {
                setShowSuccessAlert(true);
                setTimeout(() => {
                    setShowSuccessAlert(false);
                    navigate('/user-profile'); // Redirect to /user-profile
                }, 5000); // Hide the alert after 3 seconds
            }
        } catch (error) {
            console.error("API Error:", error);
        }
    };


    useEffect(() => {
        getUserProfile(token).then((res) => {
            const profile = res.applicant_profile;
            // console.log("-------profileInEdit", profile);

            const selectedCategories = profile.categories
                ? profile.categories.map((category) => ({
                    value: category.id,
                    label: category.title,
                }))
                : [];

            setFormData({
                name: profile.fullname || "",
                job_title: profile.job_title || "",
                nation: profile.nationality || "",
                date_birth: profile.date_birth || "",
                gender: profile.gender || "",
                street: profile.street || "",
                city: profile.city || "",
                country: profile.country || "",
                military: profile.military_status || "",
                maritalStatus: profile.marital_status || "",
                image: profile.profile_image || profileImg,
                work_experience_years: profile.work_experience_years || "", // Ensure correct key here
                selectedCategories: selectedCategories,
            });

            // Check if all fields are filled
            const isComplete = Object.values(profile).every(
                (field) => field !== "" && field !== null
            );
            setIsFormComplete(isComplete);
        });
    }, [token]);

    // Fetch categories
    useEffect(() => {
        async function fetchCategories() {
            try {
                const result = await getCategories(token);
                setCategories(result.map(category => ({
                    value: category.id,
                    label: category.title, // Corrected to use 'title' for the label
                })));
                // console.log("categories", result);
            } catch (error) {
                console.error("Error fetching categories:", error);
            }
        }

        fetchCategories();
    }, [token]);

    return (
        <div className={styles.personalInfoContainer}>
            <div className={styles.personalInfoHeader}>
                <h2>Basic Information</h2>
                <h3>This information you can update anytime.</h3>
            </div>
            <div className={styles.photoSection}>
                <div className={styles.photoHead}>
                    <h2>Profile Photo</h2>
                    <h3>
                        This image will be shown publicly as your profile picture, it will
                        help recruiters recognize you!
                    </h3>
                </div>
                <div className="d-flex gap-3">
                    <img
                        src={formData.image || profileImg}
                        alt="profile"
                        className={styles.profileImage}
                        key={imageKey}
                    />
                    <div className={styles.uploadImgSection}>
                        <label htmlFor="upload-image" className={styles.uploadLabel}>
                            <img src={iconPhoto} alt="icon" />
                            <h4>
                                <span>Click to replace </span>
                            </h4>
                            <h5>SVG, PNG, JPG (max. 400 x 400px)</h5>
                        </label>
                        <input
                            id="upload-image"
                            type="file"
                            className={styles.uploadInput}
                            onChange={handleImageChange}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.personalDetailsSection}>
                <h2>Personal Details</h2>
                <form onSubmit={handleSubmit} className={styles.formSection}>
                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Full Name *</h4>
                        <TextField
                            name="name"
                            value={formData.name}
                            onChange={(e) => handleChange("name", e.target.value)}
                            fullWidth
                            margin="normal"
                            className={styles.txtField}
                        />
                    </div>

                    <div className={`mt-3 ${styles.txtFieldContainer}`}>
                        <h4 className={styles.fieldsName}>Job Title *</h4>
                        <TextField
                            name="jobTitle"
                            value={formData.job_title}
                            onChange={(e) => handleChange("job_title", e.target.value)}
                            fullWidth
                            margin="normal"
                            className={styles.txtField}
                        />
                    </div>

                    <FormControl fullWidth margin="normal">
                        <h4 className={styles.fieldsName}>Years of Experience *</h4>
                        <Select
                            options={experienceLevels.map((level) => ({
                                value: level,
                                label: level,
                            }))}
                            value={{ value: formData.work_experience_years, label: formData.work_experience_years }} // Correct key
                            onChange={(selectedOption) =>
                                handleChange("work_experience_years", selectedOption.value) // Correct key
                            }
                            className={styles.selectFieldLarge}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "transparent",
                                    color: "black",
                                    border: "1px solid black",
                                    borderRadius: "4px",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "var(--main_color)"
                                        : "white",
                                    color: state.isSelected ? "white" : "var(--main_color)",
                                }),
                            }}
                        />
                    </FormControl>

                    <div className={styles.txtFieldContainer}>
                        <h4 className={styles.fieldsName}>Categories</h4>
                        <Select
                            isMulti
                            value={formData.selectedCategories}
                            onChange={handleCategoryChange}
                            options={categories}
                            styles={customStyles}
                            className={styles.test}
                            required
                        />
                    </div>

                    <FormControl fullWidth margin="normal">
                        <h4 className={styles.fieldsName}>Nationality *</h4>
                        <Select
                            options={nationalities.map((nation) => ({
                                value: nation,
                                label: nation,
                            }))}
                            value={{ value: formData.nation, label: formData.nation }}
                            onChange={(selectedOption) =>
                                handleChange("nation", selectedOption.value)
                            }
                            className={styles.selectFieldLarge}
                            styles={{
                                control: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: "transparent",
                                    color: "black",
                                    border: "1px solid black",
                                    borderRadius: "4px",
                                }),
                                option: (provided, state) => ({
                                    ...provided,
                                    backgroundColor: state.isSelected
                                        ? "var(--main_color)"
                                        : "white",
                                    color: state.isSelected ? "white" : "var(--main_color)",
                                }),
                            }}
                        />
                    </FormControl>

                    <div className="d-flex gap-3">
                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>Date of Birth *</h4>
                            <TextField
                                name="date_birth"
                                type="date"
                                value={formData.date_birth}
                                onChange={(e) => handleChange("date_birth", e.target.value)}
                                fullWidth
                                margin="normal"
                                className={styles.selectFieldSmall}
                                error={!!dateError}
                                helperText={dateError}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>Gender *</h4>
                            <Select
                                options={["Male", "Female"].map((gender) => ({
                                    value: gender,
                                    label: gender,
                                }))}
                                value={{ value: formData.gender, label: formData.gender }}
                                onChange={(selectedOption) =>
                                    handleChange("gender", selectedOption.value)
                                }
                                className={styles.selectFieldSmall}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "transparent",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "4px",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                            ? "var(--main_color)"
                                            : "white",
                                        color: state.isSelected ? "white" : "var(--main_color)",
                                    }),
                                }}
                            />
                        </FormControl>
                    </div>

                    <FormControl margin="normal" className="w-100">
                        <h4 className={styles.fieldsName}>Address * </h4>
                        <div className={styles.addressContainer}>
                            <TextField
                                name="street"
                                value={formData.street}
                                onChange={(e) => handleChange("street", e.target.value)}
                                margin="normal"
                                className={styles.txtField}
                                placeholder='street'
                            />

                            <TextField
                                name="city"
                                value={formData.city}
                                onChange={(e) => handleChange("city", e.target.value)}
                                margin="normal"
                                className={styles.txtField}
                                placeholder='city'
                            />

                            <FormControl fullWidth margin="normal">
                                <Select
                                    options={countries.map((country) => ({
                                        value: country,
                                        label: country,
                                    }))}
                                    value={{ value: formData.country, label: formData.country }}
                                    onChange={(selectedOption) =>
                                        handleChange("country", selectedOption.value)
                                    }
                                    className={styles.txtField}
                                    styles={{
                                        control: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: "transparent",
                                            color: "black",
                                            border: "1px solid black",
                                            borderRadius: "4px",
                                        }),
                                        option: (provided, state) => ({
                                            ...provided,
                                            backgroundColor: state.isSelected
                                                ? "var(--main_color)"
                                                : "white",
                                            color: state.isSelected ? "white" : "var(--main_color)",
                                        }),
                                    }}
                                />
                            </FormControl>
                        </div>
                    </FormControl>

                    <div className="d-flex gap-3">
                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>Military status</h4>
                            <Select
                                options={militaryStatuses.map((status) => ({
                                    value: status,
                                    label: status,
                                }))}
                                value={{ value: formData.military, label: formData.military }}
                                onChange={(selectedOption) =>
                                    handleChange("military", selectedOption.value)
                                }
                                className={styles.selectFieldSmall}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "transparent",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "4px",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                            ? "var(--main_color)"
                                            : "white",
                                        color: state.isSelected ? "white" : "var(--main_color)",
                                    }),
                                }}
                            />
                        </FormControl>

                        <FormControl fullWidth margin="normal">
                            <h4 className={styles.fieldsName}>Marital status</h4>
                            <Select
                                options={maritalStatuses.map((status) => ({
                                    value: status,
                                    label: status,
                                }))}
                                value={{
                                    value: formData.maritalStatus,
                                    label: formData.maritalStatus,
                                }}
                                onChange={(selectedOption) =>
                                    handleChange("maritalStatus", selectedOption.value)
                                }
                                className={styles.selectFieldSmall}
                                styles={{
                                    control: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: "transparent",
                                        color: "black",
                                        border: "1px solid black",
                                        borderRadius: "4px",
                                    }),
                                    option: (provided, state) => ({
                                        ...provided,
                                        backgroundColor: state.isSelected
                                            ? "var(--main_color)"
                                            : "white",
                                        color: state.isSelected ? "white" : "var(--main_color)",
                                    }),
                                }}
                            />
                        </FormControl>
                    </div>
                    {showSuccessAlert && (
                        <Alert
                            variant="filled"
                            severity="success"
                            onClose={() => setShowSuccessAlert(false)}
                        >
                            Data updated successfully!
                        </Alert>
                    )}

                    <div className={styles.saveBtnSection}>
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            disabled={!isFormComplete}
                        >
                            Save Changes
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default PersonalInfo;
