import React, { useEffect, useState } from "react";
import { Box, FormControlLabel, FormGroup, List, ListItem, Typography , Checkbox} from "@mui/material";
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

const CategoryFilter = () => {
    const [categories, setCategories] = useState([]);

    useEffect(()=>{
        setCategories(["Sales", "Software Engineering", "Logistics & Warehouse", "Human Resources"])
    },[])
    
    return (
        <>
        <Box sx={{display:"flex", flexDirection:"column"}}>
            <Box px={2} py={2} sx={{display:"flex", flexDirection:"row", background: "rgba(254, 239, 221, 0.35)"}}>
                <FilterListOutlinedIcon sx={{color:"#EB7A12"}} />
                <Typography variant="h4" color="#13266C">Filter By Category</Typography>
            </Box>
            <FormGroup  sx={{
                background: "rgba(255, 255, 255, 0.7)",
                border: "1.21983px solid rgba(255, 255, 255, 0.7)",
                boxShadow: "0px 39.0345px 39.0345px -29.2759px rgba(0, 0, 0, 0.1)",
                borderRadius: "19.5172px",
                display:"flex",
                flexDirection:"column",
                px:2, 
                py:2
            }}>
                {categories && categories.length>0 && categories.map(category=>(
                    <FormControlLabel key={category} control={
                        <Checkbox
                            label={category}
                            sx={{
                                color: "#F0940A",
                                '&.Mui-checked': {
                                color: "#F0940A",
                                },
                            }}
                        />
                    } label={category} />
                ))}
            </FormGroup>
        </Box>
        </>
    )
}
export default CategoryFilter;