import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from "./EducationSection.module.css";

export default function EducationSection() {
    // experience list hundle
    const [inputList, setInputList] = useState([{ University: "", Degree: "", Fieldofstudy: "", start: "", end: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { University: "", Degree: "", Fieldofstudy: "", start: "", end: "" }]);
    };
    return (
        <div>
            <h2 className={styles.text_h1}><AddCircleIcon onClick={handleAddClick} />Add Education</h2>
            {inputList.map((x, i) => {
                return (
                    <div className={styles.div_shadow}>
                        <div>
                            {/* {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>} */}
                            {/* <h2 className={styles.text_h1}>Education {i + 1}</h2> */}
                            <div className='d-flex justify-content-end '><RemoveCircleOutlineIcon className={styles.remove} onClick={() => handleRemoveClick(i)} /></div>
                            <div className='d-flex w-100 justify-content-between my-2'>
                                <div className={styles.input_field}>
                                    <label>University</label>
                                    <input
                                        name="University"
                                        placeholder="EX: Cairo University"
                                        value={x.University}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex my-2 w-100 justify-content-between'>
                                <div className={styles.input_field}>
                                    <label>Degree</label>
                                    <input
                                        name="Email"
                                        placeholder="Email"
                                        value={x.Degree}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                            <div className='d-flex flex-column w-100'>
                                <label>Field of study</label>
                                <input
                                    name="Fieldofstudy"
                                    placeholder="Mentor,manger,etc...."
                                    value={x.Fieldofstudy}
                                    className="form-control w-75"
                                    onChange={e => handleInputChange(e, i)}
                                />
                            </div>
                            <div className='d-flex my-2 w-100 justify-content-between'>
                                <div className={styles.input_field}>
                                    <label>Strat Date</label>
                                    <input
                                        type="date"
                                        name="start"
                                        placeholder="start"
                                        value={x.start}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>End Date</label>
                                    <input
                                        type="date"
                                        name="end"
                                        placeholder="end"
                                        value={x.end}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                );
            })}
        </div>
    )


}
