import React from 'react';
import userimage from '../../../images/Ellipse 2.png'

function ReviewExperience() {
    return (
        <>
            <div className='container my-5 m-auto'>
                <div className='row first-div '>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Work Experience</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>References</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Categories</h3>
                    </div>
                </div>
                <div>
                    <div className='row' style={{ width: "90%", background: "#FEEFDD", borderRadius: "16px", marginTop: "1px" }}>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>Years Of Experience :</span> 4 </p>
                                                <div>
                                                    <h5 className='text-our-color'>Job experience 1</h5>
                                                    <span>Senior UI/UX Product Designer</span><br />
                                                    <span className='text-warning'> Linked In</span><br />
                                                    <span>Directly collaborated with CEO and Product team to prototype, design and deliver the UI and UX experience with a lean design process: research, design, test, and iterate.
                                                    </span>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br"></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr tl"></div>
                                    <div class="content">
                                        <div className='d-flex'>
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>Name :</span> Mai Ahmed</p>
                                                <p><span className='text-our-color'>Title :</span> Manger</p>
                                                <p><span className='text-our-color'>phone Number :</span>+20101000000</p>
                                                <p><span className='text-our-color'>Relationship :</span>Direct Manger</p>
                                                <p><span className='text-our-color'>Email :</span>Mai@gmail.com</p>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br bl"></div>
                                    {/* <div class="bevel bl br"></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tl"></div>
                                    <div class="content">
                                        <div className='d-flex'>
                                            <div className='mt-3'>
                                                <p><span className='text-our-color'>Sales</span></p>
                                                <p><span className='text-our-color'>Software Engineering</span></p>
                                                <p><span className='text-our-color'>Logistics & warehouse</span></p>
                                                <p><span className='text-our-color'>Humane resources</span></p>
                                                <p><span className='text-our-color'>Operations</span></p>
                                            </div>
                                        </div></div>
                                    <div class="bevel bl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default ReviewExperience