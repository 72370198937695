import React from "react"; 
import TableBuilder from './TableBuilder';
import CustomChip from "./CustomChip";
import { Box } from "@mui/system";

// Status Chip component
const createStatusChip = (status) => {
    const colors = 
    {
        "Active": {
            bgColor:"#FFF3DB", text: "#F2994A"
        }, 
        "Completed": { 
            bgColor: "#BBF3E0", text:"#12A474"
        }, 
        "Archived":{
            bgColor: "#FFDBDB", text: "#EB5757"
        } 
    }
  return(
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
    <CustomChip text={status} color= {colors[status]} width="auto" height="auto"/>
    </Box>
  )
}

// MOCK DATA: TO BE REPLACED WITH DATA FROM API
const rows = [
  {number:'123',col1: 'Gertrude Pabst', col2:createStatusChip("Active")},
  {number:'43', col1:'Agneth Taube', col2:createStatusChip("Completed")},
  {number:'123',col1: 'Gertrude Pabst', col2:createStatusChip("Archived")},
  {number:'43',col1: 'Agneth Taube', col2:createStatusChip("Active")},
  {number:'123', col1:'Gertrude Pabst', col2:createStatusChip("Completed")},
  {number:'434',col1: 'Immanuel Plank', col2:createStatusChip("Archived")}
];

const NumberOfVacancies = () => {
    return (
        <>
        <TableBuilder rows={rows} titles={["#", "Vacancy", "Status"]} />
        </>
    )
}
export default NumberOfVacancies;