import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box, ListItemIcon, Button } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import Swal from "sweetalert2";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LoginIcon from "@mui/icons-material/Login";
import InfoIcon from "@mui/icons-material/Info";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CelebrationIcon from "@mui/icons-material/Celebration";
// import EmailIcon from "@mui/icons-material/Email";
// import SendIcon from "@mui/icons-material/Send";
import { useLocation, useParams } from "react-router-dom";
import { API_URL } from "../config";
import CircularProgressComponent from "../components/CircularProgressComponent";
import { setCategoryResults } from "../features/categoryResultsSlice";

function TestAPIs(props) {
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  let location = useLocation();
  const [showActions, setShowActions] = useState(true);
  let [finalResult, setFinalResult] = useState([]);
  // to set actions to hidden if location is dashboard
  const state = useSelector((state) => state);
  // 
  const dispatch = useDispatch();
  // const [myCat, setMyCat] = useState("");
  let [loading, setLoading] = useState(false);

  let [users, setUsers] = useState([]);
  let usersOfCourses = [];
  let coursesId = [];
  let myUsersData = [];
  // let [myList, setMyList] = useState([]);
  let [results, setResults] = useState([]);
  let { id } = useParams();
  let [filtered, setFiltered] = useState([]);
  let [tempData, seTempData] = useState([]);

  const addUserToPipeLine = (user, id) => {
    // 
    // 
    let body = {
      vacancyId: id,
      // userId: loggedUser.userData._id,
      username: user.username,
      email: user.email,
      name: user.name,
      bio: user.bio,
      course_certificates: user.course_certificates,
      country: user.country,
      id: user.id,
      action_status: "uncontacted",
      image_url_full: user.profile_image.image_url_full,
      last_login: user.last_login,
      date_joined: user.date_joined,
      year_of_birth: user.year_of_birth,
    };
    // );
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/create`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.apiStatus == true) {
          Swal.fire({
            position: "top-end",
            icon: "success",
            title: `${user.username} has been added successfully`,
            showConfirmButton: false,
            timer: 1500,
            showCloseButton: true,
            backdrop: true,
            allowOutsideClick: true,
          });
        }
        if (result.apiStatus == false) {
          Swal.fire({
            position: "top-end",
            icon: "warning",
            title: `${user.username} may be added before`,
            showConfirmButton: false,
            timer: 1500,
            showCloseButton: true,
            backdrop: true,
            allowOutsideClick: true,
          });
        }
      })
      .catch((error) => console.error(error));
  };

  const getUsersData = async (arr) => {
    setLoading(true);
    for (let i = 0; i < arr.length; i++) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("authorization", `JWT ${state.tokenReducer}`);
      myHeaders.append(
        "X-CSRFToken",
        "hRrKeaHVEch7QvW4aBYea6IYlZcF7J2ih8EhLJbh4A5bzKkg5D5ytTc2ndcFazip"
      );
      myHeaders.append(
        "Cookie",
        "csrftoken=N94GwAvoRgvO8kxC2nQVtI0x3t8iDBWwjS4W0439ZzV3gUjwYoG3Ti8uZ5ZI3q8d; openedx-language-preference=en; sessionid=1|ecsicliqgug4u3usghuvmiui15y4s5yj|533DFxQLxrIl|Ijk4Nzc4MjVjN2EyNThkZDAxZWQwMGU0MzQ3Mjc4OTRlYTFhMmU4NzhlMDUyNWE4ZWNiNDFkNDM2NGM5YTk5ZTci:1oaa1j:vr1GT0y3gnBvEeftdAlsOP0-bsM"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };

      await fetch(
        `https://experience.eyouthlearning.com/api/user/v1/accounts/${arr[i]}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // 
          myUsersData.push(result);
        })
        .catch((error) => console.error(error));
    }
    setLoading(false);
    dispatch(setCategoryResults(myUsersData));

    // setFinalResult(myUsersData);
    // setTempData(finalResult);
  };

  const fetchUserAndId = async () => {
    for (let i = 0; i < results.length; i++) {
      var myHeaders = new Headers();
      myHeaders.append("accept", "application/json");
      myHeaders.append("authorization", `JWT ${state.tokenReducer}`);
      myHeaders.append(
        "X-CSRFToken",
        "nyawkiidp5YKFCOdKFv0FCshbw8gscBDvVEUArI7onGxhQyHvgpAnH710Ig3FoxD"
      );
      myHeaders.append(
        "Cookie",
        "csrftoken=N94GwAvoRgvO8kxC2nQVtI0x3t8iDBWwjS4W0439ZzV3gUjwYoG3Ti8uZ5ZI3q8d; openedx-language-preference=en; sessionid=1|ecsicliqgug4u3usghuvmiui15y4s5yj|533DFxQLxrIl|Ijk4Nzc4MjVjN2EyNThkZDAxZWQwMGU0MzQ3Mjc4OTRlYTFhMmU4NzhlMDUyNWE4ZWNiNDFkNDM2NGM5YTk5ZTci:1oaa1j:vr1GT0y3gnBvEeftdAlsOP0-bsM"
      );

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow",
      };
      results[i].course_id = results[i].course_id
        .split(":")
        .join("%3A")
        .split("+")
        .join("%2B");
      await fetch(
        `https://experience.eyouthlearning.com/api/enrollment/v1/enrollments?course_id=${results[i].course_id}`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          // 
          for (let j = 0; j < result.results.length; j++) {
            if (!usersOfCourses.includes(result.results[j].user))
              usersOfCourses.push(result.results[j].user);
          }
        })
        .catch((error) => console.error(error));
    }
    setUsers(usersOfCourses);
  };

  const filterResults = (arr, f) => {
    return arr.filter((obj) => obj.org === f);
  };

  const getCoursesId = (arr) => {
    arr.forEach((r) => {
      coursesId.push(r.course_id);
      // 
    });
    // setMyList(coursesId);
  };

  const fetchData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append(
      "Cookie",
      "sessionid=1|ecsicliqgug4u3usghuvmiui15y4s5yj|S2ZCz68VFBO8|Ijk4Nzc4MjVjN2EyNThkZDAxZWQwMGU0MzQ3Mjc4OTRlYTFhMmU4NzhlMDUyNWE4ZWNiNDFkNDM2NGM5YTk5ZTci:1oZrq4:1U5e5wLT0TyjQewCkV9BGau3PAE"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    await fetch(
      "https://experience.eyouthlearning.com/api/courses/v1/courses/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        getCoursesId(result.results);
        // 
        setResults(filterResults(result.results, state.categoryReducer));
      })
      .catch((error) => console.error(error));
  };

  const showFiltered = () => {
    setFiltered(
      state.categoryResultsReducer.filter((val) => {
        if (props.search === "") {
          return val;
          // setFinalResult(tempData);
        } else {
          return val.name
            .toLowerCase()
            .includes(props.search.toLocaleLowerCase());
        }
      })
    );
    // setFinalResult(final);
  };

  useEffect(() => {
    // 
    // fetchData();
    if (location.pathname.split("/")[1] === "dashboard") setShowActions(false);
  }, []);
  useEffect(() => {
    // 
    fetchUserAndId();
  }, [results]);
  
  useEffect(() => {
    // 
    // 
    getUsersData(users);
  }, [users]);
  // useEffect(() => {
  //   
  // }, [finalResult]);
  useEffect(() => {
    // 
    // setMyCat(props.categoryValue);
    // 
    fetchData();
    setLoading(true);
  }, [state.categoryReducer]);

  useEffect(() => {
    // setTempData(finalResult);
    showFiltered();
  }, [props.search]);
  return (
    <>
      {/* {myList.length === 0 ? (
        <p>no items</p>
      ) : (
        <>
          <h1 style={{ color: "black" }}>Courses IDs</h1>
          <ul>
            {myList.map((c, i) => (
              <li key={i}>
                <Button>{c}</Button>
              </li>
            ))}
          </ul>
        </>
      )}

      <p>divider</p> */}
      {/* {!results || results.length === 0 ? (
        <p>no items to this category</p>
      ) : (
        <>
          <h1 style={{ color: "black" }}>Courses to Org</h1>
          <ul>
            {results.map((r, i) => (
              <li key={i}>
                <p>{r.id}</p>
              </li>
            ))}
          </ul>
        </>
      )} */}

      {/* <p>divider</p> */}

      {/* <Button onClick={fetchUserAndId}> Fetch User & Id</Button> */}

      {/* {!users || users.length === 0 ? (
        <p>no items </p>
      ) : (
        <>
          <h1 style={{ color: "black" }}>Courses ID with User</h1>
          <ul>
            {users.map((u, i) => (
              <li key={i}>
                <label>username: </label>
                <p>{u}</p>
              </li>
            ))}
          </ul>
        </>
      )} */}
      {/* {!users || (users.length === 0 && <p> No </p>)}

      <p>divider</p> */}

      {loading && (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgressComponent size={75} />
        </Box>
      )}
      {/* {!state.categoryResultsReducer ||
      state.categoryResultsReducer.length === 0 ? (
        <>
          {!loading && (
            <h3 style={{ color: "black", textAlign: "center" }}>
              Please select an option
            </h3>
          )}
        </>
      ) : ( */}
        <>
          {props.search === "" ? (
            <Box>
              <List sx={{ width: "100%", bgcolor: "background.paper", py: 0 }}>
                {state.categoryResultsReducer.map((f) => (
                  <>
                    <Box
                      key={f.id}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#e9ecef;",
                        },
                      }}
                    >
                      <ListItem alignItems="center">
                        <ListItemAvatar sx={{ width: 80 }}>
                          <Avatar
                            alt={`${f.name} profile picture`}
                            src={f.profile_image.image_url_full}
                            sx={{ width: 64, height: 64 }}
                          />
                        </ListItemAvatar>

                        <ListItemText primary={f.name} />
                        {/* <small></small> */}
                        {showActions && (
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-around flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                addUserToPipeLine(f, id);
                              }}
                            >
                              Add to pipeline
                            </Button>
                            {/* <ActionsDropdown /> */}
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        )}
                      </ListItem>
                      {f.country !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary={f.country} />
                          {/* <small>country</small> */}
                        </ListItem>
                      ) : null}

                      {f.email !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <AlternateEmailIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.email} />
                          {/* <small>email</small> */}
                        </ListItem>
                      ) : null}
                      {f.username !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <BadgeIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.username} />
                          {/* <small>username</small> */}
                        </ListItem>
                      ) : null}
                      {f.last_login !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <LoginIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.last_login.split("T")[0]} />
                          {/* <small>last_login</small> */}
                        </ListItem>
                      ) : null}
                      {f.year_of_birth !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <CelebrationIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.year_of_birth} />
                          {/* <small>year_of_birth</small> */}
                        </ListItem>
                      ) : null}
                      {f.date_joined !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <PersonAddAlt1Icon />
                          </ListItemIcon>

                          <ListItemText primary={f.date_joined.split("T")[0]} />
                          {/* <small>date_joined</small> */}
                        </ListItem>
                      ) : null}
                      {f.bio !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <InfoIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.bio} />
                          {/* <small>bio</small> */}
                        </ListItem>
                      ) : null}

                      <Divider component="li" />
                    </Box>
                  </>
                ))}
              </List>
            </Box>
          ) : (
            <Box>
              <List sx={{ width: "100%", bgcolor: "background.paper", py: 0 }}>
                {filtered.map((f) => (
                  <>
                    <Box
                      key={f.id}
                      sx={{
                        "&:hover": {
                          backgroundColor: "#e9ecef;",
                        },
                      }}
                    >
                      <ListItem alignItems="center">
                        <ListItemAvatar sx={{ width: 80 }}>
                          <Avatar
                            alt={`${f.name} profile picture`}
                            src={f.profile_image.image_url_full}
                            sx={{ width: 64, height: 64 }}
                          />
                        </ListItemAvatar>

                        <ListItemText primary={f.name} />
                        {/* <small></small> */}
                        {showActions && (
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-around flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                addUserToPipeLine(f, id);
                              }}
                            >
                              Add to pipeline
                            </Button>
                            {/* <ActionsDropdown /> */}
                            {/* <EmailIcon
                        sx={{ fontSize: "2rem" }}
                        onClick={() => {
                          
                        }}
                      />
                      <SendIcon
                        sx={{ fontSize: "2rem" }}
                        onClick={() => {
                          
                        }}
                      /> */}
                          </Box>
                        )}
                      </ListItem>
                      {f.country !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <LocationOn />
                          </ListItemIcon>
                          <ListItemText primary={f.country} />
                          {/* <small>country</small> */}
                        </ListItem>
                      ) : null}

                      {f.email !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <AlternateEmailIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.email} />
                          {/* <small>email</small> */}
                        </ListItem>
                      ) : null}
                      {f.username !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <BadgeIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.username} />
                          {/* <small>username</small> */}
                        </ListItem>
                      ) : null}
                      {f.last_login !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <LoginIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.last_login.split("T")[0]} />
                          {/* <small>last_login</small> */}
                        </ListItem>
                      ) : null}
                      {f.year_of_birth !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <CelebrationIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.year_of_birth} />
                          {/* <small>year_of_birth</small> */}
                        </ListItem>
                      ) : null}
                      {f.date_joined !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <PersonAddAlt1Icon />
                          </ListItemIcon>

                          <ListItemText primary={f.date_joined.split("T")[0]} />
                          {/* <small>date_joined</small> */}
                        </ListItem>
                      ) : null}
                      {f.bio !== null ? (
                        <ListItem sx={{ pl: 4 }}>
                          <ListItemIcon>
                            <InfoIcon />
                          </ListItemIcon>
                          <ListItemText primary={f.bio} />
                          {/* <small>bio</small> */}
                        </ListItem>
                      ) : null}

                      <Divider component="li" />
                    </Box>
                  </>
                ))}
              </List>
            </Box>
          )}
        </>
      {/* )} */}
    </>
  );
}

export default TestAPIs;
