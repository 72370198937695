import { Alert, Autocomplete, Button, FormControl, FormGroup, FormHelperText, IconButton, Input, InputAdornment, InputLabel, OutlinedInput, Stack, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../../images/image2 (1).png"
import styles from "./NewSignUp.module.css"
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import PersonIcon from '@mui/icons-material/Person';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link } from "react-router-dom";
import { Box } from "@mui/system";
import SchoolIcon from '@mui/icons-material/School';
import { CountriesCode } from "./countriesCode";
import { useFormik } from "formik";
import * as Yup from "yup";



function NewSignUp() {

    const [alert, setAlert] = useState(false);
    const [succssAlert, setSuccssAlert] = useState(false)

    React.useEffect(() => {
        const timer = window.setTimeout(() => {
            setAlert(false)
        }, 5000);
        return () => {
            window.clearTimeout(timer);
        };
    }, [alert]);

    const handelsignup = async (data) => {
        let obj = { user_data: "", profile: "" };
        let user_data = { user_data: { password: data.password, email: data.email, phone: data.phone } };
        let profile = { profile: { firstName: data.firstName, lastName: data.lastName, gender: data.gender, birthDate: data.birthDate, address: data.address, jobTitle: data.jobTitle, nationality: data.nationality, company: data.company, phone: data.phone } };
        obj = { ...obj, ...user_data, ...profile };
        // 
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(obj),
            redirect: 'follow'
        };
        const signupFetch = await fetch("https://hiringportal.eyouthlearning.com/recruiter/register/", requestOptions)
        try {
            const callSignupFetch = await signupFetch.json()
            return callSignupFetch
        } catch (error) {
            console.error(error, "errrr");
        }
    };

    let phoneno = /^\d{10}$/;

    const validationSchema = Yup.object({
        firstName: Yup.string().trim().required("First Name is required")
            .min(3, "Minimum chars are 3"),
        lastName: Yup.string().trim().required("Last Name is required")
            .min(3, "Minimum chars are 3"),
        email: Yup.string().trim().email("Enter a valid email address")
            .required("Email is required"),
        password: Yup.string().required("Password is required").trim().min(8, "Minimum chars are 8"),
        phone: Yup.string().required("Phone is required").matches(phoneno, "phone number isn't match real phone number"),


    })
    const formik = useFormik({
        initialValues: {
            email: "",
            phone: "",
            password: "",
            firstName: "",
            lastName: "",
            gender: "",
            birthDate: "2019-11-11",
            address: "",
            jobTitle: "",
            nationality: "",
            company: ""
        },
        validationSchema: validationSchema,
        onSubmit: async (values, actions) => {
            const resFromSignUp = await handelsignup(values);
            if (resFromSignUp.success) {
                setSuccssAlert(true);

                setTimeout(() => {
                    setSuccssAlert(false);
                }, 10000);




            } else {
                setAlert(true);
            }
        }
    })
    const [loading, setLoading] = React.useState(false);
    const [contractUploaded, setContractUploaded] = React.useState({});
    function handleClick() {
        setLoading(true);
    }
    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleUploadContract = (event) => {
        
        setContractUploaded(event.target.files[0])
        formik.values.contract = event.target.files[0]
        alert("file uploaded successfully")
        
    }


    return (
        <>
            <div className={styles.signupDiv}>
                <div className="container-fluid">
                    <div className="row">
                        <div className={`col-3 ${styles.positionImage} ${styles.toBeHidden}`}>
                            <img src={logo} className={styles.imageWidth} alt="eyouth-logo" />
                        </div>
                        <div className={`col-9 ${styles.formOne}`}>
                            <h1 className={styles.textLogin}>Create Account </h1>
                            <div className={styles.tabContainer}>
                                <Link to="/signup"><div className={styles.tabSignup}>
                                    <PersonIcon sx={{ fontSize: "2.5rem", color: "#F0940A", mx: 2 }} />
                                    <h3>Hiring Partner </h3>
                                </div></Link>
                                <Link to="/signup/candidate"><div className={styles.tabCandidate}>
                                    <SchoolIcon sx={{ fontSize: "2.5rem", color: "#F0940A", mx: 2 }} />
                                    <h3>Candidate </h3>
                                </div></Link>
                            </div>
                            <Box
                                component="form"
                                autoComplete
                                className={styles.formWidth}
                                onSubmit={formik.handleSubmit}
                            >
                                <div className={styles.inputContainer}>
                                    <TextField sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }}
                                        className={styles.inputWidth}
                                        id="firstName"
                                        label="First Name"
                                        variant="standard"
                                        placeholder="First Name"
                                        value={formik.values.firstName}
                                        name="firstName"
                                        autoComplete="firstName"
                                        onChange={formik.handleChange}
                                        error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                        helperText={formik.errors.firstName} />
                                    <TextField sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }}
                                        className={styles.inputWidth}
                                        id="lastName"
                                        label="Last Name"
                                        variant="standard"
                                        placeholder="Last Name"
                                        value={formik.values.lastName}
                                        name="lastName"
                                        autoComplete="lastName"
                                        onChange={formik.handleChange}
                                        error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                        helperText={formik.errors.lastName} />
                                </div>
                                <div className={styles.inputContainer}>
                                    <TextField sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }} className={styles.inputWidth} id="email" label="Email" variant="standard"
                                        placeholder="example@example.com"
                                        value={formik.values.email}
                                        name="email"
                                        autoComplete="email"
                                        onChange={formik.handleChange}
                                        error={formik.touched.email && Boolean(formik.errors.email)}
                                        helperText={formik.errors.email} />
                                    <FormControl sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }}
                                        error={formik.touched.password && Boolean(formik.errors.password)}
                                        variant="standard">
                                        <InputLabel htmlFor="password">Password</InputLabel>
                                        <Input type={showPassword ? 'text' : 'password'}
                                            className={styles.inputWidth} id="password" label="Password" variant="standard"
                                            value={formik.values.password}
                                            name="password"
                                            autoComplete="password"
                                            onChange={formik.handleChange}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                    >
                                                        {showPassword ? <VisibilityOff className="icon-color" /> : <Visibility className="icon-color" />}
                                                    </IconButton>
                                                </InputAdornment>
                                            } />
                                        <FormHelperText sx={{ marginTop: "-12px" }}>{formik.errors.password}</FormHelperText>
                                    </FormControl>

                                </div>

                                <div className={styles.inputContainer} style={{ alignItems: "baseline" }} >
                                    {/* <div className={styles.contractInputWidth}>
                                        <p>Contract</p>
                                        <label htmlFor="uploadContract"
                                            className={styles.label}
                                        >
                                            Upload
                                        </label>
                                        <input type="file" id="uploadContract" name="myContract" accept=".pdf" onChange={handleUploadContract} />
                                        {formik.touched.contract && Boolean(formik.errors.contract) &&
                                            <small>{formik.errors.contract}</small>}
                                    </div> */}
                                    {/* <FormControl sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }}
                                        error={formik.touched.contract && Boolean(formik.errors.contract)}
                                        variant="standard" className={`${styles.inputWidth} ${styles.specificMargin}`} >
                                        <Input
                                            sx={{ lineHeight: "1.25em" }}
                                            variant="standard"
                                            placeholder="Contract"
                                            value={contractUploaded.name}
                                            endAdornment={
                                                <InputAdornment position="end">
                                                    <label htmlFor="uploadContract"
                                                        className={styles.label}
                                                    >
                                                        Upload
                                                    </label>
                                                    <input type="file" id="uploadContract" name="myContract" accept=".pdf" onChange={handleUploadContract} />
                                                </InputAdornment>
                                            } />
                                        <FormHelperText>{formik.errors.contract}</FormHelperText>
                                    </FormControl> */}

                                    <TextField sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }}
                                        className={styles.inputWidth}
                                        type="number"
                                        id="phone"
                                        label="Phone Number"
                                        variant="standard"
                                        placeholder="01234567890"
                                        value={formik.values.phone}
                                        name="phone"
                                        autoComplete="phone"
                                        onChange={formik.handleChange}
                                        error={formik.touched.phone && Boolean(formik.errors.phone)}
                                        helperText={formik.errors.phone} />

                                    {/* <TextField sx={{
                                        '& .MuiInput-root': {
                                            '&::after': {
                                                borderBottom: "2px solid #f0940a"
                                            }
                                        }

                                    }} className={styles.inputWidth} id="contract" label="Contract" variant="standard" endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                            >
                                                {showPassword ? <VisibilityOff className="icon-color" /> : <Visibility className="icon-color" />}
                                            </IconButton>
                                        </InputAdornment>
                                    } /> */}
                                    {/* <Autocomplete
                                        id="country-select-demo"
                                        className={styles.inputWidth}
                                        options={CountriesCode}
                                        autoHighlight
                                        getOptionLabel={(option) => option.label}
                                        renderOption={(props, option) => (
                                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                                {option.label} ({option.code}) +{option.phone}
                                            </Box>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                sx={{
                                                    '& .MuiInput-root': {
                                                        '&::after': {
                                                            borderBottom: "2px solid #f0940a"
                                                        }
                                                    }

                                                }}
                                                variant="standard"
                                                {...params}
                                                label="Choose a country"
                                                inputProps={{
                                                    ...params.inputProps,
                                                    autoComplete: 'new-password', // disable autocomplete and autofill
                                                }}
                                            />
                                        )}
                                    /> */}
                                </div>

                                <div className="d-flex justify-content-center">
                                    <Button
                                        sx={{
                                            minHeight: "35px",
                                            background: "#263997",

                                            "&:hover": {
                                                background: "rgb(228, 227, 227)",
                                                color: "#263997"
                                            }

                                        }}
                                        fullWidth
                                        // onClick={handleClick}
                                        type="submit"
                                        variant="contained"
                                    >
                                        <span>Create an account</span>
                                    </Button>
                                </div>
                                <div className="d-flex justify-content-center align-items-center mt-2">
                                    <Typography variant="subtitle1" gutterBottom>
                                        Already have an account ?,
                                    </Typography>
                                    <Link to="/signin"><Typography variant="subtitle2" sx={{ color: "#263997" }} gutterBottom>
                                        &nbsp;Login</Typography></Link>


                                </div>
                            </Box>
                            {succssAlert && <Stack sx={{ width: '50%' }} className='my-5' spacing={2}>
                                <Alert variant="filled" severity="success">Recruiter created successfully But you need to actived your Account </Alert>
                            </Stack>}
                            {alert && <Stack sx={{ width: '50%' }} className='my-5' spacing={2}>
                                <Alert variant="filled" severity="error">User already exists with the same email address</Alert>
                            </Stack>}
                        </div>
                    </div>
                </div>
            </div >

        </>

    );
}
export default NewSignUp;
