import React from "react";
import styled from "@emotion/styled";
import Tabs from '@mui/material/Tabs';


const VacancyStyledTabs = styled(({ className, ...other }) => {
    return (
      <Tabs
        {...other}
        classes={{
          root: className,
          flexContainer: "flexContainer",
          indicator: "indicator"
        }}
        variant="fullWidth"
        TabIndicatorProps={{ children: <span /> }}
        centered
      />
    );
  })({
    "& .indicator": {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "transparent",
      "& > span": {
        maxWidth: 150,
        width: "100%",
        backgroundColor: "#263997"
      }
    },
    "& .flexContainer": {
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center"
      // width:'fit-content'
    }
  });

export default VacancyStyledTabs;