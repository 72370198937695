import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

const CustomChip = ({text, color, width, height})=>{
 
    return (
        <Box sx={{
            display:"flex", 
            justifyContent:"center", 
            alignItems:"center", 
            bgcolor:`${color.bgColor}`,
            borderRadius: "5.58357px",
            width:`${width}`,
            height:`${height}`
        }} 
            p={1}
            >
            <Typography color={color.text} sx={{fontWeight: "400", fontSize: "10.0504px"}}>
                {text}</Typography>
        </Box>
    )
}
export default CustomChip;