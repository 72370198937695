import React from 'react';
import { Typography , Button, Box} from '@mui/material';
import TableBuilder from './TableBuilder';

// Action component
const createAction = (number) => {
  const handleClick = (action) =>{
    /** TODO: INSERT CODE OF TAKING ACTION TOWARDS A PARTNER REQUEST HERE  
     * THEN ADD IT To THE ON CLICK OF THE COMING BUTTONS*/  
    alert(`${action} partner number ${number} request`);
  }
  return(
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"space-around"}}>
      <Button sx={{backgroundColor:"#FFF3DB"}} onClick={()=>handleClick("view")}>
      <Typography color="#F2994A" sx={{textTransform:"capitalize",fontWeight: "500", fontSize: "7.81699px"}}>View Contract</Typography>
      </Button>
      <Button sx={{backgroundColor:"#BBF3E0"}} onClick={()=>handleClick("approve")}>
      <Typography color="#12A474" sx={{textTransform:"capitalize", fontWeight: "500", fontSize: "7.81699px"}}>Approve</Typography>
      </Button>
      <Button sx={{backgroundColor:"#FFDBDB"}} onClick={()=>handleClick("reject")}>
      <Typography color="#EB5757" sx={{textTransform:"capitalize", fontWeight: "500", fontSize: "7.81699px"}}>Reject</Typography>
      </Button>
    </Box>
  )
}

// MOCK DATA: TO BE REPLACED WITH DATA FROM API
const rows = [
  {number:'123',col1: 'Gertrude Pabst', col2:createAction(123)},
  {number:'43', col1:'Agneth Taube', col2:createAction(43)},
  {number:'234',col1: 'Gertrude Pabst', col2:createAction(234)},
  {number:'230',col1: 'Agneth Taube', col2:createAction(230)},
  {number:'23', col1:'Gertrude Pabst', col2:createAction(23)},
  {number:'434',col1: 'Immanuel Plank', col2:createAction(434)},
  {number:'233',col1: 'Gertrude Pabst', col2:createAction(233)},
  {number:'24', col1:'Immanuel Plank', col2:createAction(24)},
  {number:'456',col1: 'Agneth Taube', col2:createAction(456)},
  {number:'35', col1:'Immanuel Plank', col2:createAction(35)},
  {number:'34', col1:'Gaufrid Forst', col2:createAction(34)},
  {number:'45', col1:'Immanuel Plank', col2:createAction(45)},
  {number:'65', col1:'Gaufrid Forst', col2:createAction(65)},
];

  
const HiringPartnersRequestsTab = () => {
  
    return (
      <>
      <TableBuilder rows={rows} titles={["#", "Name", "Action"]}/>
      </>
    );

}
export default HiringPartnersRequestsTab