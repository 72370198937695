import { ThemeProvider, createTheme } from "@mui/material";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import EditCompanyProfile from "./components/EditCompanyProfile/EditCompanyProfile";
import Footer from "./components/Footer/Footer";
import ResponsiveAppBar from "./components/Header/AppBar";
import MyJobs from "./components/MyJobs/MyJobs";
import Pipelines from "./components/Pipelines";
import HiringPartnersRequestsTab from "./components/adminDashboard/HiringPartnersRequestsTab";
import NumberOfVacancies from "./components/adminDashboard/NumberOfVacancies";
import PartnersAccounts from "./components/adminDashboard/PartnersAccounts";
import AccountEdits from "./pages/Account-Editing/AccountEdit";
import Account from "./pages/Account/Account";
import AllCompanies from "./pages/AllCompanies/AllCompanies";
import { AllJobs } from "./pages/AllJobs/AllJobs";
import CompanyProfile from "./pages/CompanyProfile/CompanyProfile";
import PartnerRequest from "./pages/PartnerRequest/PartnerRequest";
import Dashboard from "./pages/Dashboard";
import EditVacancy from "./pages/EditVacancy";
import Externals from "./pages/Externals";
import Home from "./pages/Home/Home";
import JobDashboardCompanyView from "./pages/JobDashboardCompanyView/JobDashboardCompanyView";
import { JobDetails } from "./pages/JobDetails/JobDetails";
import PageNotFound from "./pages/PageNotFound";
import Partners from "./pages/Partners/Partners";
import PostJob from "./pages/PostJob/PostJob";
import SideBar from "./pages/Profile-Pages/SideBar";
import NewSignUpCandidate from "./pages/Sign-Up-Candidate/NewSignUpCandidate";
import NewSignUp from "./pages/Sign-Up/NewSignUp";
import SignIn from "./pages/SignIN/SignIn";
import SingleVacancy from "./pages/SingleVacancy";
import TalentPool from "./pages/TalentPool";
import { EditProfile } from "./pages/UserProfile/EditProfile/EditProfile";
import UserProfile from "./pages/UserProfile/UserProfile";
import UserProfileCompanyPreview from "./pages/UserProfileCompanyPreview/UserProfileCompanyPreview";
import VacancyDashboard from "./pages/VacancyDashboard";
import MyVacanciesTabs from "./pages/myVacanciesTabs/MyVacanciesTabs";
import NewVacancy from "./pages/newVacancy/NewVacancy";
import EditJob from "./pages/EditJob/EditJob";
import Hunt from "./components/Hunt/Hunt";
import { AuthProvider } from "../src/Middleware/AuthContext";
import ProtectedRoute from "../src/Middleware/ProtectedRoute";
import { checkAndRefreshToken, getUserData } from "./Middleware/Constans";
// import { checkAndRefreshToken } from './components/JobSeeker/JobSeeker';

const theme = createTheme({
  palette: {
    hp: {
      blue: "#263997",
      orange: "#F0940A",
      darkGray: "#3F3F46",
    },
    primary: {
      // blue
      main: "#263997",
    },
    secondary: {
      //orange
      main: "#F0940A",
    },
    text: {
      //dark gray
      main: "#3F3F46",
    },
    accent: {
      main: "#61CE70",
    },
  },
  typography: {
    h1: {
      fontSize: "64px",
      color: "white",
    },
    h2: {
      fontSize: "2.375rem",
    },
    h3: {
      fontSize: "28px",
    },
    h4: {
      fontWeight: "100",
      fontSize: "20px",
    },
    h5: {
      fontWeight: "600",
      fontSize: "15px",
      lineHeight: "49px",
      color: "text.main",
    },
    h6: {
      fontWeight: "500",
      // fontSize: "2rem",
      lineHeight: "49px",
    },
    fontFamily: ['"Poppins"', "sans-serif"].join(","),
  },
});

function App() {
  const navigate = useNavigate();
  useEffect(() => {
    checkAndRefreshToken().then(() => {
      // console.log('Token check and refresh complete');
    });
  }, [navigate]);


  return (
    <ThemeProvider theme={theme}>
      <div className="App">
        <AuthProvider>
          <ResponsiveAppBar />
          <div
            style={{
              minHeight: "40vh",
            }}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/home" element={<Home />} />
              <Route exact path="/signin" element={<SignIn />} />
              <Route exact path="/signup" element={<NewSignUpCandidate />} />
              <Route
                exact
                path="/partner-request"
                element={<PartnerRequest />}
              />
              {/* <Route exact path="/signup" element={<NewSignUp />} /> */}

              {/* Protected Routes is here */}
              <Route
                exact
                path="/partners"
                element={
                  <ProtectedRoute>
                    <Partners />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/user-profile"
                element={
                  <ProtectedRoute>
                    <UserProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/user-profile/edit-profile"
                element={
                  <ProtectedRoute>
                    <EditProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/job-dashboard/:companyName/:jobSlug"
                element={
                  <ProtectedRoute>
                    <JobDashboardCompanyView />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/job-dashboard/userprofile/:username"
                element={
                  <ProtectedRoute>
                    <UserProfileCompanyPreview />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/edit-job/:companyName/:jobSlug"
                element={
                  <ProtectedRoute>
                    <EditJob />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/user-profile/edit-profile"
                element={
                  <ProtectedRoute>
                    <EditProfile />
                  </ProtectedRoute>
                }
              />
              {/* <Route
              exact
              path="/job-dashboard/:companyName/:jobSlug"
              element={<JobDashboardCompanyView />}
            /> */}
              {/* <Route
              exact
              path="/job-dashboard/userprofile/:username"
              element={<UserProfileCompanyPreview />}
            /> */}

              {/* <Route
              exact
              path="/signup/candidate"
              element={<NewSignUpCandidate />}
            /> */}

              {/* <Route path="/signup">
          <Route index element={<NewSignUp />} />
            <Route path='candidate' element={<NewSignUpCandidate />} />
        </Route> */}
              <Route
                exact
                path="/profile"
                element={
                  <ProtectedRoute>
                    <SideBar />
                  </ProtectedRoute>
                }
              ></Route>
              {/* <Route exact path="/edit-profile/:id" element={<EditProfile />} /> */}
              <Route
                exact
                path="/dashboard"
                element={
                  <ProtectedRoute>
                    <Dashboard />
                  </ProtectedRoute>
                }
              >
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <HiringPartnersRequestsTab />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="hiring-partners-requests"
                  element={
                    <ProtectedRoute>
                      <HiringPartnersRequestsTab />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="vacancies"
                  element={
                    <ProtectedRoute>
                      <NumberOfVacancies />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="partners-vacancies"
                  element={
                    <ProtectedRoute>
                      <PartnersAccounts />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                path="/talent-pool"
                element={
                  <ProtectedRoute>
                    <TalentPool />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/vacancy-dashboard/:id"
                element={
                  <ProtectedRoute>
                    <VacancyDashboard />
                  </ProtectedRoute>
                }
              >
                <Route
                  path="pipelines"
                  element={
                    <ProtectedRoute>
                      <Pipelines />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                exact
                path="/new-vacancy"
                element={
                  <ProtectedRoute>
                    <NewVacancy />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/edit-vacancy/:id"
                element={
                  <ProtectedRoute>
                    <EditVacancy />
                  </ProtectedRoute>
                }
              />
              {/* <Route exact path="/my-vacancies" element={<PageNotFound />} /> */}
              <Route
                exact
                path="/my-vacancies"
                element={
                  <ProtectedRoute>
                    <MyVacanciesTabs />
                  </ProtectedRoute>
                }
              >
                {/* <Route path="active" element={<MyVacancies />} />
            <Route path="archived" element={<MyVacanciesArchived />} /> */}
              </Route>
              <Route
                exact
                path="/my-vacancies/:id"
                element={
                  <ProtectedRoute>
                    <SingleVacancy />
                  </ProtectedRoute>
                }
              />
              <Route path="/account">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProtectedRoute>
                      <AccountEdits />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                exact
                path="/externals"
                element={
                  <ProtectedRoute>
                    <Externals />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/companies"
                element={
                  <ProtectedRoute>
                    <AllCompanies />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/all-jobs"
                element={
                  <ProtectedRoute>
                    <AllJobs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/job-details/:companyName/:jobSlug"
                element={
                  <ProtectedRoute>
                    <JobDetails />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/my-jobs"
                element={
                  <ProtectedRoute>
                    <MyJobs />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/hunt"
                element={
                  <ProtectedRoute>
                    <Hunt />
                  </ProtectedRoute>
                }
              />

              <Route path="*" element={<PageNotFound />} />
              {/* <Route exact path="/test-api" element={<TestAPIs />} /> */}
              {/* <Route path="about" element={<About />} /> */}
              {/* </Routes> */}
              {/* <Footer /> */}
              {/* </Route> */}
              <Route
                exact
                path="/my-vacancies/:id"
                element={
                  <ProtectedRoute>
                    <SingleVacancy />
                  </ProtectedRoute>
                }
              />
              <Route path="/account">
                <Route
                  index
                  element={
                    <ProtectedRoute>
                      <Account />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path=":id"
                  element={
                    <ProtectedRoute>
                      <AccountEdits />
                    </ProtectedRoute>
                  }
                />
              </Route>
              <Route
                exact
                path="/externals"
                element={
                  <ProtectedRoute>
                    <Externals />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/companies"
                element={
                  <ProtectedRoute>
                    <AllCompanies />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/companyProfile/:id"
                element={
                  <ProtectedRoute>
                    <CompanyProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/companyProfile/:id/editProfile"
                element={
                  <ProtectedRoute>
                    <EditCompanyProfile />
                  </ProtectedRoute>
                }
              />
              <Route
                exact
                path="/companyProfile/:id/postJob"
                element={
                  <ProtectedRoute>
                    <PostJob />
                  </ProtectedRoute>
                }
              />

              <Route
                path="*"
                element={
                  // <ProtectedRoute>
                  <PageNotFound />
                  // </ProtectedRoute>
                }
              />
              {/* <Route exact path="/test-api" element={<TestAPIs />} /> */}
              {/* <Route path="about" element={<About />} /> */}
            </Routes>
          </div>
          <Footer />
        </AuthProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
