import React, { useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import styles from "./LanguageSection.module.css";
import Select from 'react-select';


export default function LanguageSection() {
    // experience list hundle
    const [inputList, setInputList] = useState([{ Language: "", Proficiency: "" }]);

    // handle input change
    const handleInputChange = (e, index) => {
        const { name, value } = e.target;
        const list = [...inputList];
        list[index][name] = value;
        setInputList(list);
    };

    // handle click event of the Remove button
    const handleRemoveClick = index => {
        const list = [...inputList];
        list.splice(index, 1);
        setInputList(list);
    };

    // handle click event of the Add button
    const handleAddClick = () => {
        setInputList([...inputList, { Language: "", Proficiency: "" }]);
    };
    const [isClearable, setIsClearable] = useState(true);
    const options = [
        { value: 'Excellent', label: 'Excellent' },
        { value: 'very Good', label: 'very Good' },
        { value: 'not bad', label: 'not bad' }
    ]
    return (
        <div>
            <h2 className={styles.text_h1}><AddCircleIcon onClick={handleAddClick} />Add Language</h2>
            {inputList.map((x, i) => {
                return (
                    <div className={styles.div_shadow}>
                        <div>
                            {/* {inputList.length - 1 === i && <button onClick={handleAddClick}>Add</button>} */}
                            <h2 className={styles.text_h1}>Language {i + 1}</h2>
                            <div className='d-flex justify-content-end '><RemoveCircleOutlineIcon className={styles.remove} onClick={() => handleRemoveClick(i)} /></div>
                            < div className='d-flex w-100 justify-content-between my-2'>
                                <div className={styles.input_field}>
                                    <label>Language</label>
                                    <input
                                        name="Language"
                                        placeholder="Language"
                                        value={x.Language}
                                        className="form-control"
                                        onChange={e => handleInputChange(e, i)}
                                    />
                                </div>
                                <div className={styles.input_field}>
                                    <label>Proficiency</label>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        isClearable={isClearable}
                                        options={options}
                                    />

                                </div>
                            </div>

                        </div>
                    </div>

                );
            })}
        </div>
    )


}
