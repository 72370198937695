import TablePaginationUnstyled, {
  tablePaginationUnstyledClasses as classes,
} from '@mui/base/TablePaginationUnstyled';
import { styled } from '@mui/system';
import React from 'react';
import CustomChip from './CustomChip';

const colors = [
    
        {bgColor:"#BDD9FE", text: "#217EFD"}
    ,
    
        {bgColor: "rgba(255, 181, 54, 0.29)",text:"#FFB536"}
    ,
    
        {bgColor: "#F4CEFA",text:"#DB5AEE"}
    ,
    
        {bgColor: "#FBD1C9",text:"#F3654A"}
    ,
    
        {bgColor: "#DCCEFB",text:"#895BF1"}
    ,
    
        {bgColor: "rgba(39, 174, 96, 0.29)",text:"#27AE60"}
    
]

const Root = styled('div')`
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border-bottom: 1px solid #ddd;
    text-align: left;
    padding: 8px;
  }

  th {
    color: #263997;
  }
`;

const CustomTablePagination = styled(TablePaginationUnstyled)`
  & .${classes.toolbar} {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }

  & .${classes.selectLabel} {
    margin: 0;
  }

  & .${classes.displayedRows} {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }

  & .${classes.spacer} {
    display: none;
  }

  & .${classes.actions} {
    display: flex;
    gap: 0.25rem;
  }
`;

  
const TableBuilder = ({rows, titles}) => {
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  
    return (
      <Root sx={{ maxWidth: '100%', width: '100%' }}>
        <table aria-label="custom pagination table">
          <thead>
            <tr>
              <th>{titles[0]}</th>
              <th>{titles[1]}</th>
              <th style={{textAlign:"center"}}>{titles[2]}</th>
            </tr>
          </thead>
          <tbody>
            {(rowsPerPage > 0
              ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : rows
            ).map((row, index) => (
              <tr key={row.number+index}>
                <td style={{width:"10%"}}>
                    <CustomChip text={row.number} color={colors[index % colors.length]} width="26px" height="25px"/>
                    </td>
                <td style={{ width: "40%" }} align="right">
                  {row.col1}
                </td>
                <td style={{ width: "50%" }} align="right">
                    {row.col2}
                </td>
              </tr>
            ))}
  
            {emptyRows > 0 && (
              <tr style={{ height: 41 * emptyRows }}>
                <td colSpan={3} />
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr>
              <CustomTablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                colSpan={3}
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                slotProps={{
                  select: {
                    'aria-label': 'rows per page',
                  },
                  actions: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </tr>
          </tfoot>
        </table>
      </Root>
    );

}
export default TableBuilder