import {
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { createTheme } from "@mui/material/styles";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as Yup from "yup";
import { API_URL } from "../config";
import "./Externals.css";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Open Sans",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },
});

function Externals() {
  const [loading, setLoading] = useState(false);
  const [orgnizations, setOrgnizations] = useState(false);
  const [interest, setInterest] = useState();
  const [militaryStatus, setMilitaryStatus] = useState();
  const [noticePeriod, setNoticePeriod] = useState();
  const [gender, setGender] = useState();
  const [age, setAge] = useState();
  const [experience, setExperience] = useState();
  const [myResume, setMyResume] = useState();
  const navigate = useNavigate();

  const navigateToHome = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    navigate("/");
  };

  const validationSchema = Yup.object({
    name: Yup.string()
      .trim()
      .required("Name is required")
      .min(3, "Minimum chars are 3"),
    interest: Yup.string().trim().required("Interest is required"),
    email: Yup.string()
      .trim()
      .email("Enter a valid email address")
      .required("Email is required"),
    phone: Yup.number().positive().integer().required("Phone is required"),
    governrate: Yup.string()
      .trim()
      .required("Governrate is required")
      .min(3, "Minimum chars are 3"),
    experience: Yup.string().trim().required("Experience is required"),
    age: Yup.string().trim().required("Age is required"),
    gender: Yup.string().trim().required("Gender is required"),
    noticePeriod: Yup.string().trim().required("Notice Period is required"),
    militaryStatus: Yup.string().trim().required("Military Status is required"),
  });

  const sendData = (values, actions) => {
    var formdata = new FormData();
    formdata.append("cv", myResume);
    formdata.append("name", values.name);
    formdata.append("phone", values.phone);
    formdata.append("email", values.email);
    formdata.append("governrate", values.governrate);
    formdata.append("experience", values.experience);
    formdata.append("age", values.age);
    formdata.append("gender", values.gender);
    formdata.append("noticePeriod", values.noticePeriod);
    formdata.append("militaryStatus", values.militaryStatus);
    formdata.append("interest", values.interest);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(`${API_URL}/externals`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        
        formik.resetForm();
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Great...",
          text: `${result.message}`,
          showConfirmButton: false,
          timer: 3500,
        }).then(() => navigateToHome());
      })
      .catch((error) => console.error(error));
    // Swal.fire({
    //   position: "center",
    //   icon: "success",
    //   title: "Your message has been sent successfully",
    //   showConfirmButton: false,
    //   timer: 2500,
    // });
    // formik.resetForm();
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      governrate: "",
      experience: "",
      age: "",
      gender: "",
      noticePeriod: "",
      militaryStatus: "",
      interest: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      // setLoading(true);
      sendData(values, actions);
    },
  });

  const handleChangeExperience = (event) => {
    setExperience(event.target.value);
    formik.values.experience = event.target.value;
  };
  const handleChangeAge = (event) => {
    setAge(event.target.value);
    formik.values.age = event.target.value;
  };
  const handleChangeGender = (event) => {
    setGender(event.target.value);
    formik.values.gender = event.target.value;
  };
  const handleChangeNoticePeriod = (event) => {
    setNoticePeriod(event.target.value);
    formik.values.noticePeriod = event.target.value;
  };
  const handleChangeMilitaryStatus = (event) => {
    setMilitaryStatus(event.target.value);
    formik.values.militaryStatus = event.target.value;
  };
  const handleChangeInterest = (event) => {
    setInterest(event.target.value);
    formik.values.interest = event.target.value;
  };

  function fetchToken() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "nlEwVuIjIpRzhaTgorxalVxoTQq0x18wZUtWpPlH");
    urlencoded.append(
      "client_secret",
      "vSiSyCh7COOcpj4kZKuQgSh1aQH8EOm1vjn4wZVmd3E6e85AbidB406Z4nVhbLkuSq8oZWptcGyynwT1Y5X2KhQhDWT76mbh51bVLomPLon6lXAdLMtsgTaoSmrHmBqh"
    );
    urlencoded.append("grant_type", "client_credentials");
    urlencoded.append("token_type", "jwt");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://experience.eyouthlearning.com/oauth2/access_token/",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => fetchOrgs(result))
      .catch((error) => console.error(error));
  }

  function fetchOrgs(data) {
    let newToken = data.access_token;
    var myHeaders = new Headers();
    myHeaders.append("accept", "application/json");
    myHeaders.append("Authorization", `JWT ${newToken}`);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://experience.eyouthlearning.com/api/organizations/v0/organizations/?page_size=1000",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        let myArr = result.results;
        setOrgnizations(myArr);
      })
      .catch((error) => console.error(error));
  }

  function FileChange(e) {
    setMyResume(e.target.files[0]);
  }

  useEffect(() => {
    document.title = "Hiring Portal - Externals";
    fetchToken();
  }, []);
  return (
    <Box sx={{ py: 2 }} id={"externals"}>
      <Container>
        <Typography
          // variant="h2"
          component="div"
          gutterBottom
          sx={{
            textAlign: "center",
            color: "black",
            fontSize: "3rem",
            my: 3,
            fontWeight: 500,
            fontFamily: { theme },
          }}
          //   className="typography-h2"
        >
          EYouth Externals Form
        </Typography>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item lg={11} className="form">
            <Box
              component="form"
              noValidate
              onSubmit={formik.handleSubmit}
              sx={{ mt: 1, position: "relative" }}
            >
              <TextField
                sx={{ backgroundColor: "white" }}
                placeholder="Name"
                value={formik.values.name}
                margin="normal"
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
              />
              {formik.touched.name && formik.errors.name ? (
                <small className="text-danger">{formik.errors.name}</small>
              ) : null}

              <TextField
                sx={{ backgroundColor: "white" }}
                placeholder="00201234567890"
                value={formik.values.phone}
                margin="normal"
                fullWidth
                name="phone"
                label="Phone"
                type="tel"
                id="phone"
                autoComplete="current-phone"
                onChange={formik.handleChange}
                error={formik.touched.phone && Boolean(formik.errors.phone)}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <small className="text-danger">{formik.errors.phone}</small>
              ) : null}

              <TextField
                sx={{ backgroundColor: "white" }}
                placeholder="example@example.com"
                value={formik.values.email}
                margin="normal"
                fullWidth
                name="email"
                label="Email"
                type="email"
                id="email"
                autoComplete="current-email"
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email ? (
                <small className="text-danger">{formik.errors.email}</small>
              ) : null}

              <TextField
                sx={{ backgroundColor: "white" }}
                placeholder="Governorate of Residence"
                value={formik.values.governrate}
                margin="normal"
                fullWidth
                id="governrate"
                label="Governrate"
                name="governrate"
                autoComplete="governrate"
                onChange={formik.handleChange}
                error={
                  formik.touched.governrate && Boolean(formik.errors.governrate)
                }
              />
              {formik.touched.governrate && formik.errors.governrate ? (
                <small className="text-danger">
                  {formik.errors.governrate}
                </small>
              ) : null}

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Years of Experience
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.experience}
                    label="Years of Experience"
                    onChange={handleChangeExperience}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"0"}>0 to 2</MenuItem>
                    <MenuItem value={"2"}>2 to 5</MenuItem>
                    <MenuItem value={"5"}>5+</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.experience && formik.errors.experience && (
                  <small className="text-danger">
                    {formik.errors.experience}
                  </small>
                )}
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Age</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.age}
                    label="Age"
                    onChange={handleChangeAge}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"18"}>18 to 23</MenuItem>
                    <MenuItem value={"23"}>23 to 28</MenuItem>
                    <MenuItem value={"28"}>28 to 32</MenuItem>
                    <MenuItem value={"32"}>32+</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.age && formik.errors.age && (
                  <small className="text-danger">{formik.errors.age}</small>
                )}
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">Gender</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.gender}
                    label="Gender"
                    onChange={handleChangeGender}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"Male"}>Male</MenuItem>
                    <MenuItem value={"Female"}>Female</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.gender && formik.errors.gender && (
                  <small className="text-danger">{formik.errors.gender}</small>
                )}
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Notice period
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.noticePeriod}
                    label="Notice period"
                    onChange={handleChangeNoticePeriod}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"Month"}>1 Month</MenuItem>
                    <MenuItem value={"2_Months"}>2 Months</MenuItem>
                    <MenuItem value={"3_Months"}>3 Months</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.noticePeriod && formik.errors.noticePeriod && (
                  <small className="text-danger">
                    {formik.errors.noticePeriod}
                  </small>
                )}
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Military Status
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.militaryStatus}
                    label="Military Status"
                    onChange={handleChangeMilitaryStatus}
                    sx={{ backgroundColor: "white" }}
                  >
                    <MenuItem value={"Exempted "}>Exempted</MenuItem>
                    <MenuItem value={"Completed"}>
                      Completed the service Military
                    </MenuItem>
                    <MenuItem value={"Postponed"}>Postponed</MenuItem>
                    <MenuItem value={"Currently serving"}>
                      Currently serving
                    </MenuItem>
                    <MenuItem value={"Does not apply"}>Does not apply</MenuItem>
                  </Select>
                </FormControl>
                {formik.touched.militaryStatus &&
                  formik.errors.militaryStatus && (
                    <small className="text-danger">
                      {formik.errors.militaryStatus}
                    </small>
                  )}
              </Box>

              <Box
                sx={{
                  maxWidth: "100%",
                  mb: 2,
                  mt: 2,
                }}
              >
                <FormControl fullWidth>
                  <InputLabel id="demo-simple-select-label">
                    Interest
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={formik.values.interest}
                    label="Interest"
                    onChange={handleChangeInterest}
                    sx={{ backgroundColor: "white" }}
                  >
                    {orgnizations === false
                      ? null
                      : orgnizations.map((ele, i) => (
                          <MenuItem key={i} value={ele.name}>
                            {ele.name}
                          </MenuItem>
                        ))}
                  </Select>
                </FormControl>
                {formik.touched.interest && formik.errors.interest && (
                  <small className="text-danger">
                    {formik.errors.interest}
                  </small>
                )}
              </Box>

              <Stack direction="row" alignItems="center" spacing={4}>
                <label htmlFor="myCV">
                  <strong>Your updated CV</strong>
                </label>
                <input
                  accept=".pdf"
                  type="file"
                  id="myCV"
                  onChange={FileChange}
                />
              </Stack>

              <Box sx={{ display: "flex", justifyContent: "end" }}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 3, mb: 2, backgroundColor: "#225ee4" }}
                >
                  Submit
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
}

export default Externals;
