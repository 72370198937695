import React from 'react';
import styles from './ProfessionalInfo.module.css';
import WorkExperienceComponent from './WorkExperienceComponent';
import EducationComponent from './EducationComponent';
import SkillsComponent from './SkillsComponent';
import LanguagesComponent from './LanguagesComponent';
import CertificationsComponent from './CertificationsComponent';

const ProfessionalInfo = (props) => {
  // console.log(props , "props")
  return (
    <div>
      <h5 className='mb-4'>Professional Info</h5>
      <div>
        <h6 className={styles.textProfessional}>About Me</h6>
        <p>{props.About_me}</p>
      </div>
      <WorkExperienceComponent />
      <EducationComponent />
      <SkillsComponent />
      <LanguagesComponent />
      <CertificationsComponent />
    </div>
  );
};

export default ProfessionalInfo;
