import React, { useState } from "react";
import { createTheme, Grid, ThemeProvider } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
// import CircularProgress from "@mui/material/CircularProgress";
import { useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import { API_URL } from "../config";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import CategoryOutlinedIcon from '@mui/icons-material/CategoryOutlined';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';

export default function SingleVacancy() {
  const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const [showArchive, setShowArchive] = useState();
  const [vacancy, setVacancy] = useState([]);
  const [editedDate, setEditedDate] = useState("");
  const { id } = useParams();
  const navigateMyVacancies = () => {
    navigate(`/my-vacancies/active`);
  };

  const moveToVacancyDashboard = () => {
    // 
    navigate(`/vacancy-dashboard/${id}/talent-pool`);
  };

  const toggleArchive = () => {
    // let test = vacancy;
    
    vacancy.archived = !vacancy.archived;
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(vacancy);
    

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setVacancy(response.data);
        setShowArchive(!showArchive);
      })
      .catch((error) => console.error(error));
  };
  const deleteVacancy = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      redirect: "follow",
    };

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
          .then((response) => response.json())
          .then(() =>
            Swal.fire(
              "Deleted!",
              `${vacancy.title} has been deleted.`,
              "success"
            )
          )
          .then(() => navigateMyVacancies())
          .catch((error) => console.error(error));
      }
    });
  };

  // useEffect(() => {
  //   document.title = `Hiring Portal - ${vacancy.title}`;
  //   if (state.loggedUserReducer.token === undefined) navigate("/signin");

  //   var myHeaders = new Headers();
  //   myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);

  //   var requestOptions = {
  //     method: "GET",
  //     headers: myHeaders,
  //     redirect: "follow",
  //   };

  //   fetch(`${API_URL}/vacancy/myvacancies/${id}`, requestOptions)
  //     .then((response) => response.json())
  //     .then((result) => {
  //       
  //       
  //       setVacancy(result.data[0]);
  //       setShowArchive("result.data[0].archived", result.data[0].archived);
  //       setEditedDate(result.data[0].createdAt.split("T"));
  //     })
  //     .catch((error) => );
  // }, []);

  useEffect(() => {
    
  }, [vacancy]);

  return (
    <Box mx={4} my={3} px={4} py={3}
      sx={{ background: "rgba(254, 239, 221, 0.35)",
              boxShadow: "10px 10px 20px rgba(0, 0, 0, 0.25)",
              borderRadius: "10px" }}>
      <Box component="div" sx={{ mt: 1, color: "#3F3F46" }}>
        <Box component="div" >
            <Box mx={2} my={3} sx={{display:"flex", flexDirection:"row"}}>
              <img src={require("../images/office-chair.svg")} />
              <Typography variant="h4">Job Title: &nbsp;
                <span style={{ color: "#F0940A" }}>
                  {/* {vacancy.title} */}
                  Orange sales Outdoor
                </span>
              </Typography>
            </Box>
            <Grid container px={4} py={3} sx={{background: "rgba(254, 239, 221, 0.35)",
              boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25), inset 0px 4px 4px rgba(0, 0, 0, 0.25)",
              '& hr': {
                mx: 0.5,
                color:"#000000",
                textColor:"#000000",
                backgroundColor: "#000000",
                my:0
              }}}>
              <Grid item xs={6} md={2}>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <LocationOnOutlinedIcon sx={{color:"#263997"}} mr={2}/>
                    <Typography variant="h3" ml={2}>Location</Typography>
                  </Box>
                  <Typography variant="h3" sx={{color:"#263997"}}>Egypt, Cairo</Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={6} md={3}>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <CategoryOutlinedIcon sx={{color:"#263997"}} />
                  <Typography variant="h3" ml={2}>Category</Typography>
                  </Box>
                  <Typography variant="h3" sx={{color:"#263997"}}>Business Analyst</Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={6} md={3}>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <HomeWorkOutlinedIcon sx={{color:"#263997"}} />
                    <Typography variant="h3" ml={2}>Job type</Typography>
                  </Box>
                  <Typography variant="h3" sx={{color:"#263997"}}>Full Time</Typography>
                </Box>
              </Grid>
              <Divider orientation="vertical" flexItem />
              <Grid item xs={6} md={3}>
                <Box sx={{display:"flex", flexDirection:"column"}}>
                  <Box sx={{display:"flex", flexDirection:"row"}}>
                    <img src={require("../images/company.svg")}/>
                    <Typography variant="h3" ml={2}>Company hiring for:</Typography>
                  </Box>
                  <Typography variant="h3" sx={{color:"#263997"}}>LinkedIn</Typography>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{display:"flex", flexDirection:"column"}} my={3}>
              <Box sx={{display:"flex", flexDirection:"row"}}>
                <img src={require("../images/promote.svg")}/>
                <Typography sx={{color:"#3F3F46"}} variant="h4" mx={2}>Experience: </Typography>
                <Typography sx={{color:"#263997"}} variant="h4">0-3</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"column"}}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                  <img src={require("../images/checklist.svg")}/>
                  <Typography sx={{color:"#3F3F46"}} variant="h4" mx={2}>Requirements: </Typography>
                </Box>
                <List>
                  <ListItem>
                    <Typography variant="h3">1. Build and maintain guest experience standards in order to build strong loyalty</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">2. Address customers' queries about products, prices, availability, product uses, and services</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">3. Communicate with customers to assess their needs, provide assistance in satisfying those needs an meeting</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">4. Advise customers on product ranges best suited to their needs Demonstrate usage and benefits of various brands and products Generate sales, while achieving line and sales ta</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">5. Demonstrate usage and benefits of various brands and products</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">6. Generate sales, while achieving line and sales targets, using make up knowledge</Typography>
                  </ListItem>
                </List>
              </Box>
              <Box sx={{display:"flex", flexDirection:"column"}}>
                <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                  <DescriptionOutlinedIcon sx={{color:"#263997", width:"30px", height:"30px"}} />
                  <Typography sx={{color:"#3F3F46"}} variant="h4" mx={2}>Roles & responsibilities: </Typography>
                </Box>
                <List >
                  <ListItem>
                    <Typography variant="h3">1. Build and maintain guest experience standards in order to build strong loyalty</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">2. Address customers' queries about products, prices, availability, product uses, and services</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">3. Communicate with customers to assess their needs, provide assistance in satisfying those needs an meeting</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography variant="h3">4. Advise customers on product ranges best suited to their needs Demonstrate usage and benefits of various brands and products Generate sales, while achieving line and sales ta</Typography>
                  </ListItem>
                </List>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <VisibilityOutlinedIcon sx={{color:"#263997", width:"30px", height:"30px"}}/>
                <Typography sx={{color:"#3F3F46"}} variant="h4" mx={2}>Visiblity: </Typography>
                <Typography sx={{color:"#FA0C00"}} variant="h4">Private</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row"}}>
                <img src={require("../images/books.svg")}/>
                <Typography sx={{color:"#3F3F46"}} variant="h4" mx={1}>Archived: </Typography>
                <Typography sx={{color:"#12B347"}} variant="h4">No</Typography>
              </Box>
              <Box sx={{display:"flex", flexDirection:"row", alignItems:"center"}}>
                <CalendarMonthOutlinedIcon sx={{color:"#263997", width:"30px", height:"30px"}} />
                <Typography sx={{color:"#3F3F46"}} variant="h4" mx={2}>Published at: </Typography>
                <Typography sx={{color:"#F0940A"}} variant="h4">2023-03-05</Typography>
              </Box>
            </Box>
        </Box>
        <Box
          component="div"
          display="flex"
          sx={{ justifyContent: "end" }}
          mb={2}
        >
          <Box
            component="div"
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", sm: "row" },
              alignItems: { xs: "flex-end" },
              width: { xs: "100%", sm: "100%", md: "75%", lg: "75%" },
              justifyContent: "end",
              px: 2,
            }}
          >
            {!vacancy.archived && (
              <Box component="div">
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<DashboardOutlinedIcon sx={{color:"#F0940A"}}/>}
                  onClick={moveToVacancyDashboard}
                  sx={{ my: { xs: 1, sm: 0 } , color:"#263997", backgroundColor:"white", border:"0.5px solid #263997"}}
                >
                  Vacancy Dashboard
                </Button>
              </Box>
            )}

            {/* <Box component="div">
              {vacancy.archived ? (
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<UnarchiveIcon />}
                  onClick={toggleArchive}
                  // sx={{ mb: { xs: 1, sm: 0 } }}
                >
                  Unarchive
                </Button>
              ) : (
                <Button
                  variant="outlined"
                  color="warning"
                  startIcon={<ArchiveIcon />}
                  onClick={toggleArchive}
                  // sx={{ mb: { xs: 1, sm: 0 } }}
                >
                  Archive
                </Button>
              )}
            </Box> */}
            {/* <Box component="div">
              <Button
                variant="outlined"
                color="error"
                startIcon={<DeleteIcon />}
                onClick={deleteVacancy}
                sx={{ my: { xs: 1, sm: 0 } }}
              >
                Delete
              </Button>
            </Box> */}
            <Box component="div" >
              <Button variant="contained" startIcon={<EditIcon />} sx={{ my: { xs: 1, sm: 0 }}}>
                <Link
                  to={`/edit-vacancy/${vacancy._id}`}
                  style={{ textDecorationLine: "none", color: "white" , backgroundColor:"#263997"}}
                  // sx={{ my: { xs: 2, sm: 0 } }}
                >
                  Edit
                </Link>
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
        
      
    </Box>
  );
}
