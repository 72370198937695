import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { createTheme, Grid, ThemeProvider } from "@mui/material";
// import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";

import SearchResultsBar from "../components/SearchResultsBar";
// import CandidatesList from "../components/CandidatesList";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";

import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
// import { useState } from "react";
import TestAPIs from "./TestAPIs";
import { changeToken } from "../features/tokenSlice";
import { useNavigate } from "react-router-dom";
import CategoryFilter from "../components/CategoryFilter";
import Talents from "../components/Talents";

function TalentPool() {
  const state = useSelector((state) => state);
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const category = useSelector((state) => state.categoryReducer);
  const tokenState = useSelector((state) => state.tokenReducer);
  // 

  const [focus, setFocus] = React.useState(false);
  const [search, setSearch] = React.useState("");

  const handleInput = (e) => {
    // 
    setSearch(e.target.value);
  };

  // const toFetchToken = () => {
  //   var myHeaders = new Headers();
  //   myHeaders.append(
  //     "Cookie",
  //     "csrftoken=N94GwAvoRgvO8kxC2nQVtI0x3t8iDBWwjS4W0439ZzV3gUjwYoG3Ti8uZ5ZI3q8d; openedx-language-preference=en; sessionid=1|ecsicliqgug4u3usghuvmiui15y4s5yj|533DFxQLxrIl|Ijk4Nzc4MjVjN2EyNThkZDAxZWQwMGU0MzQ3Mjc4OTRlYTFhMmU4NzhlMDUyNWE4ZWNiNDFkNDM2NGM5YTk5ZTci:1oaa1j:vr1GT0y3gnBvEeftdAlsOP0-bsM"
  //   );

  //   var formdata = new FormData();
  //   formdata.append("client_id", "login-service-client-id");
  //   formdata.append("grant_type", "password");
  //   formdata.append("token_type", "jwt");
  //   formdata.append("username", "TechAdmin");
  //   formdata.append("password", "eyouthadmin12345");

  //   var requestOptions = {
  //     method: "POST",
  //     headers: myHeaders,
  //     body: formdata,
  //     redirect: "follow",
  //   };

  //   fetch(
  //     "https://experience.eyouthlearning.com/oauth2/access_token/",
  //     requestOptions
  //   )
  //     .then((response) => response.json())
  //     .then((result) => {
  //       // 
  //       // setToken(result.access_token);
  //       dispatch(changeToken(result.access_token));
  //       // 
  //     })
  //     .catch((error) => );
  // };

  // useEffect(() => {
  //   document.title = "Hiring Portal - Dashboard";
  //   if (state.loggedUserReducer.token === undefined) navigate("/signin");
  //   // toFetchToken();
  // }, []);

  // useEffect(() => {
  //   
  // }, [categoryValue]);
  // useEffect(() => {
  //   
  // }, [category]);

  return (
    <Box sx={{ minHeight: "100vh" }}>
    <Box display="flex" justifyContent="center">
        <Paper
        component="form"
        elevation={0}
        sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: {
            sm: "50%",
            xs: "100%",
            },
            my: "20px",
            border: "0.5px solid #263997",
            borderRadius: "31.2363px",
            // backgroundColor: "#000000",
            "&:hover": {
            boxShadow: "rgba(3, 102, 214, 0.3) 0px 0px 0px 3px",
            },
        }}
        >
        <IconButton type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon sx={{ color: "black" }} />
        </IconButton>
        <InputBase
            sx={{
            ml: 1,
            flex: 1,
            color: "black",
            }}
            placeholder="Start a new search..."
            inputProps={{}}
            onChange={(e) => {
            handleInput(e);
            }}
            onBlur={() => {
            setFocus(false);
            }}
            onFocus={() => {
            setFocus(true);
            }}
        />
        </Paper>
    </Box>
    <Grid
        container
        gap={4}
        mt={5}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
    >
        <Grid
        item
        xs={11}
        sm={10}
        md={4}
        lg={3}
        sx={{ backgroundColor: "white" }}
        >
        <CategoryFilter />
        </Grid>
        <Grid item xs={11} sm={10} md={7} lg={8} sx={{ backgroundColor: "white" }}>
        {/* <TestAPIs search={search} /> */}
        <Talents search={search} />
        </Grid>
    </Grid>
    </Box>
  );
}

export default TalentPool;
