export const Cities = [
    "10th of Ramadan City",
    "6th October",
    "Abu Ghosoun",
    "Abu Kir",
    "Abu Rudeis",
    "Abu Simbel",
    "Abu Zenimah",
    "Ain Sukhna",
    "Al Adabiyah",
    "Al Alamein International Apt / Daaba City",
    "Al Bahr El Ahmer",
    "Al Fayyum",
    "Al Haram",
    "Giza",
    "Al Mansurah",
    "Al Manzilah",
    "Al Minya",
    "Al Monofiya",
    "Al Qantarah El Sharqiyya",
    "Al Qusayr",
    "Al Wadi El Gedeed",
    "Almazah",
    "An Nubariyah",
    "An Nukhaylah",
    "Suez",
    "Assiut",
    "Aswan",
    "Asyut",
    "Atakka Dry Port",
    "Az Zaqaziq",
    "Badr",
    "Bahtim",
    "Beni Suef",
    "Bilbays",
    "Borg El Arab",
    "Burj al Arab",
    "Cairo",
    "Dakhla",
    "Damanhour",
    "Dumyat",
    "El Beheirah",
    "El Dekheila",
    "El Dekhela",
    "Alexandria",
    "El Mahalla El Kobra",
    "El Mansoura",
    "El Obour",
    "Cairo",
    "El Tor",
    "El'Arish",
    "El Gouna",
    "Ezbet Osman Murtada",
    "Fa'id",
    "Faiyum",
    "Fanara",
    "Geisum Terminal",
    "Gerga",
    "Halaib",
    "Hamrawein",
    "Heliopolis",
    "Helwan",
    "Hurghada",
    "Idku",
    "Ismailia",
    "Itay Al Barud",
    "Izbat Sidi Ali Murghib",
    "Kafr az Zayyat",
    "Kafr El Sheikh",
    "Kenna",
    "Luxor",
    "Mallawi",
    "Marsa Alam",
    "Matruh",
    "Meet Ghamr",
    "Mersa El Hamra",
    "Mersa Matru",
    "Minyat Al Hayt",
    "Nasr City",
    "New Cairo",
    "New Valley",
    "Nubaria",
    "Nuwaiba",
    "Obour",
    "Port Ibrahim",
    "Port Said",
    "Port Said East",
    "Port Said West",
    "Port Tewfik",
    "Qalyub",
    "Quesna",
    "Ras Budran",
    "Ras Gharib",
    "Ras Shukheir",
    "Ras Sudr",
    "Rashid",
    "Sadat City",
    "Safaga",
    "Sakr Koreish",
    "Sallum",
    "Santa Katarina",
    "Shamal Sinai",
    "Shark El Oweinat",
    "Sharm ash Shaykh",
    "Shebin Al Kawm",
    "Sidi Barrani",
    "Sidi Kerir Terminal",
    "Siwa",
    "Sixth of October City",
    "Sohag",
    "Sokhna Port",
    "Sosdi / 6th October",
    "South Sinai",
    "Suez Canal",
    "Taba",
    "Tanta",
    "Tour Sinai City",
    "Wadi Feiran",
    "Zeit Bay",
    "Ad Dakhla",
    "Ejbei Uad el Aabd",
    "El Aaiun",
    "Ad Dakhla"

]