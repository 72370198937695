import { Download, Edit } from '@mui/icons-material'
import { Avatar, Button, Typography } from '@mui/material'
import { indigo } from '@mui/material/colors'
import React from 'react'
import styles from './Account.module.css'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { Link } from 'react-router-dom'

function Account() {
    return (
        <>
            <div className={styles.banner}>
                <div className={styles.bannerContent}>
                    <Avatar
                        alt="user.fullName"
                        sx={{ width: 96, height: 96, justifySelf: "flex-start", bgcolor: indigo[900] }}
                    />
                    <div>
                        <Link to='/account/52352'>
                            <Button variant="contained" startIcon={<ModeEditOutlineIcon />} sx={{ background: "#263997", fontWeight: "bold" }}>
                                Edit Account
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
            <div className={styles.detailsContent}>
                <Typography variant="h6" gutterBottom>
                    Name: &nbsp;<span style={{ color: '#263997' }}>user.FullName</span>
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Email: &nbsp;<span style={{ color: '#263997' }}>user.email</span>
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Phone: &nbsp;<span style={{ color: '#263997' }}>user.phone</span>
                </Typography>
                <div>
                    <Button variant="contained" startIcon={<Download />} sx={{ background: "#263997", fontWeight: "bold" }}>
                        View Contract
                    </Button>
                    {/* <a href='/somefile.txt' download>Click to download</a>
                    <Link to="/files/myfile.pdf" target="_blank" download>Download</Link> */}

                </div>

            </div>


        </>


    )
}

export default Account