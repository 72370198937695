import { ArrowBackIos, ArrowForwardIos, Logout } from '@mui/icons-material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LinkIcon from '@mui/icons-material/Link';
import SchoolIcon from '@mui/icons-material/School';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import * as React from 'react';
import Attachment from './Attachment';
import Education from './Education';
import Experience from './Experience';
import GeneralInfo from './GeneralInfo';
import Review from './Review';
import "./SideBar.css";


function SideBar(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ pt: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

SideBar.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

export default function VerticalTabs() {

    const [value, setValue] = React.useState(0);
    const [open, setOpen] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const logOut = () => {
        
    }

    return (
        <>

            <Box
                sx={{ flexGrow: 1, bgcolor: 'background.paper', display: 'flex', position: "relative" }}
            >
                {/* <Button onClick={() => { setOpen(!open) }}>burger menu</Button> */}

                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={value}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{
                        borderRight: 1,
                        borderColor: 'Highlight',
                        bgcolor: "#1B307F",
                        overflow: "visible",
                        minHeight: "50vh",
                        display: `${open ? "none" : "block"}`
                    }}
                >

                    <Tab sx={{ fontSize: "0.7rem", color: "white" }} icon={<CalendarMonthIcon />} label="General Info" {...a11yProps(0)} />
                    <Tab sx={{ fontSize: "0.7rem", color: "white" }} icon={<WorkHistoryIcon />} label="Experience" {...a11yProps(1)} />
                    <Tab sx={{ fontSize: "0.7rem", color: "white" }} icon={<SchoolIcon />} label="Education" {...a11yProps(2)} />
                    <Tab sx={{ fontSize: "0.7rem", color: "white" }} icon={<LinkIcon />} label="Attachment" {...a11yProps(3)} />
                    <Tab sx={{ fontSize: "0.7rem", color: "white" }} icon={<TextSnippetIcon />} label="Review" {...a11yProps(4)} />
                    <div onClick={logOut} className="lastButton">
                        <Logout sx={{ width: "100%" }} />
                        <div>logout</div>
                    </div>
                </Tabs>
                <div className={open ? "myArrowStyle" : ""}>
                    <IconButton aria-label="showHide" size="small" onClick={() => setOpen(!open)}>
                        {open ?
                            <ArrowForwardIos className="hiddenMenu" fontSize="small" />
                            :
                            <ArrowBackIos className="shownMenu" fontSize="small" />}
                    </IconButton>
                </div>
                <SideBar value={value} index={0}>
                    <GeneralInfo />
                </SideBar>
                <SideBar value={value} index={1}>
                    <Experience />
                </SideBar>
                <SideBar value={value} index={2}>
                    <Education />
                </SideBar>
                <SideBar value={value} index={3}>
                    <Attachment />
                </SideBar>
                <SideBar value={value} index={4}>
                    <Review />
                </SideBar>
            </Box >
        </>

    );
}