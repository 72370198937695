import React from 'react';

function ReviewEducation() {
    return (
        <>
            <div className='container my-5 m-auto'>
                <div className='row first-div '>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Education</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Certificates</h3>
                    </div>
                    <div className='col-4 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Languages</h3>
                    </div>
                </div>
                <div>
                    <div className='row' style={{ width: "90%", background: "#FEEFDD", borderRadius: "16px", marginTop: "1px" }}>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <div className='mt-3'>
                                                <h5><span className='text-our-color'>Ain Shams University</span></h5>
                                                <div>
                                                    <h5 className='text-our-color'>Job experience 1</h5>
                                                    <span>Bachelor's degree ,Computer and Information Sciences</span><br />
                                                    <h5><span className='text-our-color'>Grade :</span> Good</h5>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br"></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr tl"></div>
                                    <div class="content">
                                        <div className='d-flex'>
                                            <div className='mt-3'>
                                                <span className='text-our-color'>Advanced User Experience Design</span><br />
                                                <span className='text-our-second-color'>Udacity </span><br />
                                                <span> Aug 2020</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br bl"></div>
                                    {/* <div class="bevel bl br"></div> */}
                                </div>
                            </div>
                        </div>
                        <div className='col-4'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tl"></div>
                                    <div class="content">
                                        <div className='d-flex'>
                                            <div className='mt-3'>
                                                <span><span className='text-our-color'>Arbic</span></span><br/>
                                                <span>native</span><br/>
                                                <span><span className='text-our-color'>English</span></span><br/>
                                                <span>Very Good</span><br/>
                                                <span><span className='text-our-color'>German </span></span><br/>
                                                <span>Good</span>
                                            </div>
                                        </div></div>
                                    <div class="bevel bl"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default ReviewEducation