import React, { useEffect, useState } from 'react';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { WEBSITE_URL, getToken } from '../../../Middleware/Constans';

function Attachments() {
  const token = getToken();
  const [pdfAttachments, setPdfAttachments] = useState([]);

  useEffect(() => {
    getUserProfile(token)
      .then((res) => {
        if (res && res.applicant_profile && Array.isArray(res.applicant_profile.cv_item)) {
          setPdfAttachments(res.applicant_profile.cv_item);
        } else {
          setPdfAttachments([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("Failed to fetch attachments:", error);
        setPdfAttachments([]); // Fallback to an empty array in case of an error
      });
  }, [token]);

  return (
    <div>
      <h5 className='mb-4'>Attachments</h5>
      {pdfAttachments.map((attachment, index) => (
        <React.Fragment key={index}>
          <div className="attachment">
            <h6>{attachment.short_description}</h6>
            <a href={`${WEBSITE_URL}${attachment.attach_file}`} download={`${WEBSITE_URL}${attachment.attach_file}`}>
              <PictureAsPdfIcon style={{ color: "red", marginRight: "20px" }} />   Download {attachment.short_description}
            </a>
          </div>
          <hr />
        </React.Fragment>
      ))}
    </div>
  );
}

export default Attachments;
