import React from "react";
import Stack from "@mui/material/Stack";
import CircularProgress from "@mui/material/CircularProgress";

function CircularProgressComponent(props) {
  return (
    <Stack sx={{ color: "indigo.500" }}>
      <CircularProgress color="inherit" size={props.size} />
      {/* <CircularProgress color="inherit" size={40} />
      <CircularProgress color="inherit" size={20} />
      <CircularProgress color="inherit" size={80} />
      <CircularProgress color="inherit" size={60} />
      <CircularProgress color="inherit" size={10} /> */}
    </Stack>
  );
}

export default CircularProgressComponent;
