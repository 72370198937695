import FacebookSharpIcon from "@mui/icons-material/FacebookSharp";
import TwitterIcon from "@mui/icons-material/Twitter";
import React from "react";
import "./footer.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { useLocation } from "react-router-dom";
import FooterLastImage from "../../images/assets/footerImageHiringPortal.png";
import { getToken, getUserData } from "../../Middleware/Constans";

export default function Footer() {
  const location = useLocation();
  const { pathname } = location;
  const token = getToken();
  const userData = getUserData();

  return (
    <>
      {pathname !== "/signin" &&
        pathname !== "/signup" &&
        pathname !== "/signup/candidate" &&
        pathname !== "/partner-request" && (
          <>
            <footer>
              <div className="bgFooter">
                <div className="row align-items-center">
                  <div className="footerImage">
                    <img
                      // className="imageFooter"
                      width="100%"
                      src={FooterLastImage}
                      alt="FooterLastImage"
                      srcSet=""
                    />
                  </div>

                  <div className="col-md-3 text-white mb-5 pb-4">
                    <h5 className="fw-bold my-4 border-left mobile-view-border">
                      Website Information{" "}
                    </h5>
                    <ul className="footerMenu">
                      {!token && (
                        <>
                          <li className="footer-menu-li my-3">
                            <a
                              className="text-decoration-none my-2 text-white"
                              href="/signin"
                            >
                              Login
                            </a>
                          </li>
                          <li className="footer-menu-li my-3">
                            <a
                              className="text-decoration-none my-2 text-white"
                              href="/signup"
                            >
                              Register
                            </a>
                          </li>
                        </>
                      )}
                      <li className="footer-menu-li my-3">
                        <a
                          className="text-decoration-none my-2 text-white"
                          href="/home"
                        >
                          Home
                        </a>
                      </li>
                      {/* <li className="footer-menu-li my-3">
                        <a
                          className="text-decoration-none my-2 text-white"
                          href="/home"
                        >
                          Contact Us
                        </a>
                      </li> */}
                      {token &&
                        <li className="footer-menu-li my-3">
                          <a
                            className="text-decoration-none my-2 text-white"
                            href="/companies"
                          >
                            Companies
                          </a>
                        </li>}
                      {(token && userData.user_type === "company") && <li className="footer-menu-li my-3">
                        <a
                          className="text-decoration-none my-2 text-white"
                          href="/hunt"
                        >
                          Hunt
                        </a>
                      </li>
                      }
                      {(token && userData.user_type != "company") && <li className="footer-menu-li my-3">
                        <a
                          className="text-decoration-none my-2 text-white"
                          href="/all-jobs"
                        >
                          Jobs
                        </a>
                      </li>
                      }
                    </ul>
                  </div>
                  <div className="col-md-4 text-white">
                    <h5 className="fw-bold my-4 border-left mobile-view-border">
                      Contact Us
                    </h5>
                    <ul className="footerMenu">
                      <li className="footer-menu-li my-3">
                        <PhoneIcon /> 01067959408
                      </li>
                      {/* <li className="footer-menu-li my-3">
                      <EmailIcon /> support@eyouthegypt.com
                    </li> */}
                      <li className="footer-menu-li my-3 ">
                        <LocationOnIcon /> 20 Gool Gamal St., Mohandessin, Giza,
                        EG.
                      </li>
                      <li className={`footer-menu-li my-3 footerLocation`}>
                        <LocationOnIcon className="locationIcon" />
                        <span>
                          Level 14, Al Khatem Tower, WeWork Hub71, Abu Dhabi
                          Global Market Square, Al Maryah Island, United Arab
                          Emirates
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* last footer */}
              <div className="lastFooter ">
                <div className="layin-lastfooter">
                  <div className="col-md-3 text-white">
                    <div className="d-flex icon-dev-res">
                      <div className="iconSpan ">
                        <a
                          className="text-white"
                          href="https://www.facebook.com/EYouthLearning/"
                        >
                          <FacebookSharpIcon />
                        </a>
                      </div>
                      <div className="iconSpan ms-3">
                        {" "}
                        <a
                          className="text-white"
                          href="https://twitter.com/eyouthlearning?s=11&t=mLl8LMthDwW6RVwDg9BxDg"
                        >
                          {" "}
                          <TwitterIcon />
                        </a>
                      </div>
                      <div className="iconSpan ms-3">
                        <a
                          className="text-white"
                          href="https://www.linkedin.com/company/eyouth/mycompany/verification/"
                        >
                          <LinkedInIcon />
                        </a>
                      </div>
                      <div className="iconSpan ms-3">
                        {" "}
                        <a
                          className="text-white"
                          href="https://instagram.com/eyouthlearning?igshid=YmMyMTA2M2Y="
                        >
                          <InstagramIcon />
                        </a>
                      </div>
                    </div>
                  </div>
                  <p className="text-center">
                    <a className="text-white bold" target="_blank" href="/home">
                      2024 @ EYouth. All rights reserved.
                    </a>
                  </p>
                </div>
              </div>
            </footer>
          </>
        )}
    </>
  );
}
