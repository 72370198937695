import React from 'react'
import EducationSection from './EducationSection/EducationSection'
import styles from "./Education.module.css"
import LanguageSection from './LanguageSection/LanguageSection'
import Certification from './Certification/Certification'

function Education() {
  return (
    <div className='container'>
      <div className={styles.experience_div}>
        <EducationSection />
        <LanguageSection />
        <Certification />
        <div className='d-flex w-100 justify-content-end'>
          <button className={styles.btn}> Save && Countine </button>
        </div>
      </div></div>
  )
}

export default Education