import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Divider } from "@mui/material";
import { Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import { Box, Button } from "@mui/material";
import { LocationOn } from "@mui/icons-material";
import BadgeIcon from "@mui/icons-material/Badge";
import ActionsDropdown from "../components/ActionsDropdown";

import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import LoginIcon from "@mui/icons-material/Login";
import InfoIcon from "@mui/icons-material/Info";
import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import CelebrationIcon from "@mui/icons-material/Celebration";

import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../config";
import { currentPipeline } from "../features/currentPipelineSLice";
import {
  REMOVE_UNCONTACTED,
  SET_UNCONTACTED,
} from "../features/uncontactedSlice";
import { REMOVE_REJECTED, SET_REJECTED } from "../features/rejectedSlice";
import { REMOVE_ACCEPTED, SET_ACCEPTED } from "../features/acceptedSlice";
import { REMOVE_PHONE, SET_PHONE } from "../features/phoneSlice";
import { REMOVE_CONTACTED, SET_CONTACTED } from "../features/contactedSlice";

export default function Pipelines() {
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  // const navigate = useNavigate();
  let [value, setValue] = useState("");
  // let [finalResult, setFinalResult] = useState([]);
  // const pipelineState = useSelector((state) => state.);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const candidate = useSelector((state) => state.candidates.value);
  const { id } = useParams();

  // const [search, setSearch] = React.useState("");

  // const filteredCandidates = candidate.filter((val) => {
  //   if (search === "") {
  //     return candidate;
  //   } else if (val.name.toLowerCase().includes(search.toLocaleLowerCase())) {
  //     return val;
  //   }
  // });

  // function removeDuplicates(arr) {
  //   return [...new Set(arr)];
  // }

  // const [filters, setFilters] = useState([]);
  const removeFromPipeline = (user) => {
    // 
    // 
    let body = {
      _id: user._id,
    };

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "DELETE",
      headers: myHeaders,
      body: JSON.stringify(body),
      redirect: "follow",
    };

    // let status = "offer_rejected";
    // switch (status) {
    //   case "uncontacted":
    //     // 
    //     dispatch(REMOVE_UNCONTACTED(user));
    //     // setFinalResult(state.uncontactedReducer);
    //     break;
    //   case "contacted":
    //     // 
    //     dispatch(REMOVE_CONTACTED(user));
    //     // setFinalResult(state.contactedReducer);
    //     break;
    //   case "phone_screen":
    //     // 
    //     dispatch(REMOVE_PHONE(user));
    //     // setFinalResult(state.phoneReducer);
    //     break;
    //   case "offer_accepted":
    //     // 
    //     dispatch(REMOVE_ACCEPTED(user));
    //     // setFinalResult(state.acceptedReducer);
    //     break;
    //   case "offer_rejected":
    //     // 
    //     dispatch(REMOVE_REJECTED(user));
    //     // setFinalResult(state.rejectedReducer);
    //     break;
    //   default:
    //     
    // }
    dispatch(REMOVE_UNCONTACTED(user));
    dispatch(REMOVE_CONTACTED(user));
    dispatch(REMOVE_PHONE(user));
    dispatch(REMOVE_ACCEPTED(user));
    dispatch(REMOVE_REJECTED(user));
    fetch(`${API_URL}/candidate/remove`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // 

        // let newArray = finalResult.filter((ele) => ele._id !== user._id);
        // 
      })
      .catch((error) => console.error("error", error));
  };

  const getUncontacted = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: `${id}`,
      action_status: "uncontacted",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/vacancyandaction`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length) {
          // 
          dispatch(SET_UNCONTACTED(result.data));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getContacted = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: `${id}`,
      action_status: "contacted",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/vacancyandaction`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length) {
          // 
          dispatch(SET_CONTACTED(result.data));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getPhone = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: `${id}`,
      action_status: "phone_screen",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/vacancyandaction`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length) {
          // 
          dispatch(SET_PHONE(result.data));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getAccepted = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: `${id}`,
      action_status: "offer_accepted",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/vacancyandaction`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length) {
          // 
          dispatch(SET_ACCEPTED(result.data));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const getRejected = () => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: `${id}`,
      action_status: "offer_rejected",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(`${API_URL}/candidate/vacancyandaction`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.data.length) {
          // 
          dispatch(SET_REJECTED(result.data));
        }
      })
      .catch((error) => console.error("error", error));
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    dispatch(currentPipeline(event.target.value));
  };

  const fetchAll = () => {
    getUncontacted();
    getContacted();
    getPhone();
    getAccepted();
    getRejected();
  };

  // useEffect(() => {
  //   
  // }, [filters]);

  // useEffect(() => {
  //   
  // }, [finalResult]);

  useEffect(() => {
    document.title = "Hiring Portal - Pipelines";
    if (state.loggedUserReducer.token === undefined) navigate("/signin");
    fetchAll();
  }, []);

  return (
    <>
      <Grid
        container
        gap={4}
        mt={2}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid item lg={3}>
          <List
            sx={{ width: "100%", bgcolor: "background.paper", pb: 0 }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <List
                component="div"
                id="nested-list-subheader"
                sx={{
                  backgroundColor: "rgba(243, 246, 249, 0.4)",
                  py: 2,
                  pl: 1,
                }}
              >
                My pipelines
              </List>
            }
          >
            <Divider component="li" />
            <List component="div" disablePadding>
              <ListItemIcon sx={{ pl: 1 }}>
                <FormControl sx={{ pl: 2 }}>
                  {/* <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel> */}
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="uncontaced"
                    name="radio-buttons-group"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  >
                    <FormControlLabel
                      value="uncontacted"
                      control={<Radio />}
                      label={`Uncontacted ${state.uncontactedReducer.length}`}
                      disabled={state.uncontactedReducer.length === 0}
                    />

                    <FormControlLabel
                      value="contacted"
                      control={<Radio />}
                      label={`Contacted ${state.contactedReducer.length}`}
                      disabled={state.contactedReducer.length === 0}
                    />
                    <FormControlLabel
                      value="phone_screen"
                      control={<Radio />}
                      label={`Phone screen ${state.phoneReducer.length}`}
                      disabled={state.phoneReducer.length === 0}
                    />
                    <FormControlLabel
                      value="offer_accepted"
                      control={<Radio />}
                      label={`Offer accepted ${state.acceptedReducer.length}`}
                      disabled={state.acceptedReducer.length === 0}
                    />
                    <FormControlLabel
                      value="offer_rejected"
                      control={<Radio />}
                      label={`Offer rejected ${state.rejectedReducer.length}`}
                      disabled={state.rejectedReducer.length === 0}
                    />
                  </RadioGroup>
                </FormControl>
              </ListItemIcon>
            </List>
          </List>
        </Grid>
        <Grid item lg={8} sx={{ backgroundColor: "white" }}>
          {/* <CandidatesList candidates={filteredCandidates || candidate} /> */}
          <Box>
            <List sx={{ width: "100%", bgcolor: "background.paper", py: 0 }}>
              {state.currentPipelineReducer === "uncontacted" &&
              state.uncontactedReducer.length !== 0
                ? state.uncontactedReducer.map((f) => (
                    <>
                      <Box
                        key={f.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e9ecef;",
                          },
                        }}
                      >
                        <ListItem alignItems="center">
                          <ListItemAvatar sx={{ width: 80 }}>
                            <Avatar
                              alt={`${f.name} profile picture`}
                              src={f.image_url_full}
                              sx={{ width: 64, height: 64 }}
                            />
                          </ListItemAvatar>

                          <ListItemText primary={f.name} />
                          {/* <small></small> */}
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-between flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                removeFromPipeline(f);
                              }}
                            >
                              {" "}
                              Remove from pipeline
                            </Button>
                            <ActionsDropdown user={f} />
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        </ListItem>
                        {f.country !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LocationOn />
                            </ListItemIcon>
                            <ListItemText primary={f.country} />
                            {/* <small>country</small> */}
                          </ListItem>
                        ) : null}

                        {f.email !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.email} />
                            {/* <small>email</small> */}
                          </ListItem>
                        ) : null}
                        {f.username !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.username} />
                            {/* <small>username</small> */}
                          </ListItem>
                        ) : null}
                        {f.last_login !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.last_login.split("T")[0]}
                            />
                            {/* <small>last_login</small> */}
                          </ListItem>
                        ) : null}
                        {f.year_of_birth !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <CelebrationIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.year_of_birth} />
                            {/* <small>year_of_birth</small> */}
                          </ListItem>
                        ) : null}
                        {f.date_joined !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>

                            <ListItemText
                              primary={f.date_joined.split("T")[0]}
                            />
                            {/* <small>date_joined</small> */}
                          </ListItem>
                        ) : null}
                        {f.bio !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.bio} />
                            {/* <small>bio</small> */}
                          </ListItem>
                        ) : null}

                        <Divider component="li" />
                      </Box>
                    </>
                  ))
                : null}
              {state.currentPipelineReducer === "contacted" &&
              state.contactedReducer.length !== 0
                ? state.contactedReducer.map((f) => (
                    <>
                      <Box
                        key={f.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e9ecef;",
                          },
                        }}
                      >
                        <ListItem alignItems="center">
                          <ListItemAvatar sx={{ width: 80 }}>
                            <Avatar
                              alt={`${f.name} profile picture`}
                              src={f.image_url_full}
                              sx={{ width: 64, height: 64 }}
                            />
                          </ListItemAvatar>

                          <ListItemText primary={f.name} />
                          {/* <small></small> */}
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-between flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                removeFromPipeline(f);
                              }}
                            >
                              {" "}
                              Remove from pipeline
                            </Button>
                            <ActionsDropdown user={f} />
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        </ListItem>
                        {f.country !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LocationOn />
                            </ListItemIcon>
                            <ListItemText primary={f.country} />
                            {/* <small>country</small> */}
                          </ListItem>
                        ) : null}

                        {f.email !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.email} />
                            {/* <small>email</small> */}
                          </ListItem>
                        ) : null}
                        {f.username !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.username} />
                            {/* <small>username</small> */}
                          </ListItem>
                        ) : null}
                        {f.last_login !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.last_login.split("T")[0]}
                            />
                            {/* <small>last_login</small> */}
                          </ListItem>
                        ) : null}
                        {f.year_of_birth !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <CelebrationIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.year_of_birth} />
                            {/* <small>year_of_birth</small> */}
                          </ListItem>
                        ) : null}
                        {f.date_joined !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>

                            <ListItemText
                              primary={f.date_joined.split("T")[0]}
                            />
                            {/* <small>date_joined</small> */}
                          </ListItem>
                        ) : null}
                        {f.bio !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.bio} />
                            {/* <small>bio</small> */}
                          </ListItem>
                        ) : null}

                        <Divider component="li" />
                      </Box>
                    </>
                  ))
                : null}
              {state.currentPipelineReducer === "phone_screen" &&
              state.phoneReducer.length !== 0
                ? state.phoneReducer.map((f) => (
                    <>
                      <Box
                        key={f.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e9ecef;",
                          },
                        }}
                      >
                        <ListItem alignItems="center">
                          <ListItemAvatar sx={{ width: 80 }}>
                            <Avatar
                              alt={`${f.name} profile picture`}
                              src={f.image_url_full}
                              sx={{ width: 64, height: 64 }}
                            />
                          </ListItemAvatar>

                          <ListItemText primary={f.name} />
                          {/* <small></small> */}
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-between flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                removeFromPipeline(f);
                              }}
                            >
                              {" "}
                              Remove from pipeline
                            </Button>
                            <ActionsDropdown user={f} />
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        </ListItem>
                        {f.country !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LocationOn />
                            </ListItemIcon>
                            <ListItemText primary={f.country} />
                            {/* <small>country</small> */}
                          </ListItem>
                        ) : null}

                        {f.email !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.email} />
                            {/* <small>email</small> */}
                          </ListItem>
                        ) : null}
                        {f.username !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.username} />
                            {/* <small>username</small> */}
                          </ListItem>
                        ) : null}
                        {f.last_login !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.last_login.split("T")[0]}
                            />
                            {/* <small>last_login</small> */}
                          </ListItem>
                        ) : null}
                        {f.year_of_birth !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <CelebrationIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.year_of_birth} />
                            {/* <small>year_of_birth</small> */}
                          </ListItem>
                        ) : null}
                        {f.date_joined !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>

                            <ListItemText
                              primary={f.date_joined.split("T")[0]}
                            />
                            {/* <small>date_joined</small> */}
                          </ListItem>
                        ) : null}
                        {f.bio !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.bio} />
                            {/* <small>bio</small> */}
                          </ListItem>
                        ) : null}

                        <Divider component="li" />
                      </Box>
                    </>
                  ))
                : null}
              {state.currentPipelineReducer === "offer_accepted" &&
              state.acceptedReducer.length !== 0
                ? state.acceptedReducer.map((f) => (
                    <>
                      <Box
                        key={f.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e9ecef;",
                          },
                        }}
                      >
                        <ListItem alignItems="center">
                          <ListItemAvatar sx={{ width: 80 }}>
                            <Avatar
                              alt={`${f.name} profile picture`}
                              src={f.image_url_full}
                              sx={{ width: 64, height: 64 }}
                            />
                          </ListItemAvatar>

                          <ListItemText primary={f.name} />
                          {/* <small></small> */}
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-between flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                removeFromPipeline(f);
                              }}
                            >
                              {" "}
                              Remove from pipeline
                            </Button>
                            <ActionsDropdown user={f} />
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        </ListItem>
                        {f.country !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LocationOn />
                            </ListItemIcon>
                            <ListItemText primary={f.country} />
                            {/* <small>country</small> */}
                          </ListItem>
                        ) : null}

                        {f.email !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.email} />
                            {/* <small>email</small> */}
                          </ListItem>
                        ) : null}
                        {f.username !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.username} />
                            {/* <small>username</small> */}
                          </ListItem>
                        ) : null}
                        {f.last_login !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.last_login.split("T")[0]}
                            />
                            {/* <small>last_login</small> */}
                          </ListItem>
                        ) : null}
                        {f.year_of_birth !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <CelebrationIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.year_of_birth} />
                            {/* <small>year_of_birth</small> */}
                          </ListItem>
                        ) : null}
                        {f.date_joined !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>

                            <ListItemText
                              primary={f.date_joined.split("T")[0]}
                            />
                            {/* <small>date_joined</small> */}
                          </ListItem>
                        ) : null}
                        {f.bio !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.bio} />
                            {/* <small>bio</small> */}
                          </ListItem>
                        ) : null}

                        <Divider component="li" />
                      </Box>
                    </>
                  ))
                : null}
              {state.currentPipelineReducer === "offer_rejected" &&
              state.rejectedReducer.length !== 0
                ? state.rejectedReducer.map((f) => (
                    <>
                      <Box
                        key={f.id}
                        sx={{
                          "&:hover": {
                            backgroundColor: "#e9ecef;",
                          },
                        }}
                      >
                        <ListItem alignItems="center">
                          <ListItemAvatar sx={{ width: 80 }}>
                            <Avatar
                              alt={`${f.name} profile picture`}
                              src={f.image_url_full}
                              sx={{ width: 64, height: 64 }}
                            />
                          </ListItemAvatar>

                          <ListItemText primary={f.name} />
                          {/* <small></small> */}
                          <Box
                            sx={{
                              width: "38%",
                            }}
                            className="d-flex justify-content-between flex-wrap"
                          >
                            <Button
                              variant="outlined"
                              onClick={() => {
                                removeFromPipeline(f);
                              }}
                            >
                              {" "}
                              Remove from pipeline
                            </Button>
                            <ActionsDropdown user={f} />
                            {/* <EmailIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        />
                        <SendIcon
                          sx={{ fontSize: "2rem" }}
                          onClick={() => {
                            
                          }}
                        /> */}
                          </Box>
                        </ListItem>
                        {f.country !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LocationOn />
                            </ListItemIcon>
                            <ListItemText primary={f.country} />
                            {/* <small>country</small> */}
                          </ListItem>
                        ) : null}

                        {f.email !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <AlternateEmailIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.email} />
                            {/* <small>email</small> */}
                          </ListItem>
                        ) : null}
                        {f.username !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <BadgeIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.username} />
                            {/* <small>username</small> */}
                          </ListItem>
                        ) : null}
                        {f.last_login !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <LoginIcon />
                            </ListItemIcon>
                            <ListItemText
                              primary={f.last_login.split("T")[0]}
                            />
                            {/* <small>last_login</small> */}
                          </ListItem>
                        ) : null}
                        {f.year_of_birth !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <CelebrationIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.year_of_birth} />
                            {/* <small>year_of_birth</small> */}
                          </ListItem>
                        ) : null}
                        {f.date_joined !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <PersonAddAlt1Icon />
                            </ListItemIcon>

                            <ListItemText
                              primary={f.date_joined.split("T")[0]}
                            />
                            {/* <small>date_joined</small> */}
                          </ListItem>
                        ) : null}
                        {f.bio !== null ? (
                          <ListItem sx={{ pl: 4 }}>
                            <ListItemIcon>
                              <InfoIcon />
                            </ListItemIcon>
                            <ListItemText primary={f.bio} />
                            {/* <small>bio</small> */}
                          </ListItem>
                        ) : null}

                        <Divider component="li" />
                      </Box>
                    </>
                  ))
                : null}
            </List>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}
