import React from "react";
import arrow from "../../images/assets/Icon.svg";
import styles from "./Companies.module.css";
import { Link } from 'react-router-dom';
import companyLogo from "../../images/notFound.jpeg";


export const Companies = (props) => {
    return (
        <div className={styles.companiesListContainer}>
            <div className={styles.companiesListHeader}>
                <h2 className={props.h2Title}>{props.Sectiontitle}</h2>
                {props.showCompaniesButton && (
                    <Link to="/companies">
                        <button>
                            Show all Companies
                            <img src={arrow} alt="arrow" />
                        </button>
                    </Link>
                )}
            </div>

            {props.subTitle && <span className='text-muted '>{props.subTitle}</span>}
            <div className={styles.companyInfoCardContainer}>
                {props.CompniesData.map((company, index) => (
                    <a
                        key={index}
                        href={`/companyProfile/${company.name}`}
                        target="_blank"
                        className={styles.companyInfoCard}
                    >
                        <div className={styles.companyLogo}>
                            <img
                                width="100px"
                                src={company.logo ? company.logo : companyLogo}
                                alt={`${company.name || ""} logo`}
                            />
                            <h5>{company.jobs || ""} Jobs</h5>
                        </div>
                        <div className={styles.companyTitle}>
                            <h2>{company.name || ""}</h2>
                            <h6 className='text-muted'>
                                {company.name} is located in
                                {company.locations && company.locations[0] ? ` ${company.locations[0].title}` : " an unknown location"}.
                            </h6>
                        </div>
                        <div className={styles.companyPurpose}>
                            <h5>{company.about || ""}</h5>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
};
