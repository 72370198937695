import React from 'react';

function ReviewOther() {
    return (
        <>
            <div className='container my-5 m-auto'>
                <div className='row first-div '>
                    <div className='col-5 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>CV</h3>
                    </div>
                    <div className='col-6 d-flex justify-content-center align-items-center'>
                        <h3 className='General-info text-our-color'>Other Atatchments</h3>
                    </div>
                </div>
                <div>
                    <div className='row' style={{ width: "90%", background: "#FEEFDD", borderRadius: "16px", marginTop: "1px" }}>
                        <div className='col-5'>
                            <div>
                                <div class="bvc">
                                    <div class="bevel tr"></div>
                                    <div class="content">
                                        <div className='d-flex align-items-center justify-content-around'>
                                            <div className='mt-3'>
                                                <h5><span className='text-our-color'> Sarah Ahmed ‘ CV</span> </h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br"></div>
                                </div>
                            </div>
                        </div>
                        <div className='col-6'>
                            <div>
                                <div class="bvc" style={{ marginLeft: "20px" }}>
                                    <div class="bevel tr tl"></div>
                                    <div class="content">
                                        <div className='d-flex'>
                                            <div className='mt-3'>
                                                <span className='text-our-color'>Certification : Advanced User Experience Design</span><br />
                                                <span className='text-our-color'>Certification : Advanced User Experience Design</span><br />
                                                <span className='text-our-color'>Certification : Advanced User Experience Design</span><br />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bevel br bl"></div>
                                    {/* <div class="bevel bl br"></div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </>

    )
}

export default ReviewOther