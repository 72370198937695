export const MartialConsts = [
    "Select marial status",
    "Single",
    "Married",
    "Divorced",
    "Separated",
    "Widowed",
    "Never Married"
]







