import * as React from "react";
import "./ActionsDropdown.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
// import { useSelector, useDispatch } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_UNCONTACTED,
  REMOVE_UNCONTACTED,
} from "../features/uncontactedSlice";
import { ADD_CONTACTED, REMOVE_CONTACTED } from "../features/contactedSlice";
import { ADD_PHONE, REMOVE_PHONE } from "../features/phoneSlice";
import { ADD_ACCEPTED, REMOVE_ACCEPTED } from "../features/acceptedSlice";
import { ADD_REJECTED, REMOVE_REJECTED } from "../features/rejectedSlice";
import { useParams } from "react-router-dom";
import { API_URL } from "../config";
import { useState } from "react";
import { useEffect } from "react";

export default function ActionsDropdown(props) {
  const loggedUser = useSelector((state) => state.loggedUserReducer);
  const { id } = useParams();
  const dispatch = useDispatch();
  const currentPipeline = useSelector((state) => state.currentPipelineReducer);
  const [unavailable, setUnavailable] = useState(true);

  let action = "";

  const removeFromCurrentStatus = () => {
    // 
    switch (currentPipeline) {
      case "uncontacted":
        dispatch(REMOVE_UNCONTACTED(props.user));
        break;
      case "contacted":
        dispatch(REMOVE_CONTACTED(props.user));
        break;
      case "phone_screen":
        dispatch(REMOVE_PHONE(props.user));
        break;
      case "offer_accepted":
        dispatch(REMOVE_ACCEPTED(props.user));
        break;
      case "offer_rejected":
        dispatch(REMOVE_REJECTED(props.user));
        break;
      default:
        
      // break;
    }
  };

  const addToDistStatus = (distStatus) => {
    // 
    switch (distStatus) {
      case "uncontacted":
        dispatch(ADD_UNCONTACTED(props.user));
        break;
      case "contacted":
        dispatch(ADD_CONTACTED(props.user));
        break;
      case "phone_screen":
        dispatch(ADD_PHONE(props.user));
        break;
      case "offer_accepted":
        dispatch(ADD_ACCEPTED(props.user));
        break;
      case "offer_rejected":
        dispatch(ADD_REJECTED(props.user));
        break;
      default:
        
      // break;
    }
  };

  const updateStatusInDB = (event) => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${loggedUser.token}`);
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      vacancyId: id,
      username: props.user.username,
      action_status: event.target.value,
    });
    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };
    fetch(`${API_URL}/candidate/editactionstatus`, requestOptions)
      .then((response) => {
        response.json();
      })
      .then((result) => {
        // 
        removeFromCurrentStatus();
        addToDistStatus(event.target.value);
      })
      .catch((error) => console.error("error", error));
  };

  const handleChange = (event) => {
    
    updateStatusInDB(event);
  };

  useEffect(() => {
    if (currentPipeline === "") {
      setUnavailable(true);
    } else {
      setUnavailable(false);
    }
  }, [currentPipeline]);

  return (
    <Box sx={{ minWidth: 95 }}>
      <FormControl sx={{ minWidth: 120 }} size="small">
        <InputLabel id="demo-select-small" sx={{ color: "white" }}>
          Actions
        </InputLabel>
        <Select
          className="borderRadiusEdited"
          labelId="demo-select-small"
          defaultValue="uncontacted"
          id="demo-select-small"
          value={action}
          label="Actions"
          onChange={handleChange}
          disabled={unavailable}
        >
          {/* <FormControl
        fullWidth
        size="small"
        sx={{ width: 95, mx: 1 }}
        variant="filled"
      > */}
          <MenuItem selected value="uncontacted">
            Uncontacted
          </MenuItem>
          <MenuItem value="contacted">Contacted</MenuItem>
          <MenuItem value="phone_screen">Phone screen</MenuItem>
          <MenuItem value="offer_accepted">Offer accepted</MenuItem>
          <MenuItem value="offer_rejected">Offer rejected</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
}

// export default function ActionsDropdown(props) {
//   return (

// <Typography
//   variant="body2"
//   color="text.secondary"
//   align="center"
//   {...props}
// >
//   {"Copyright © "}
//   <Link color="inherit" href="/" underline="none">
//     EYouth
//   </Link>{" "}
//   {new Date().getFullYear()}
//   {"."}
// </Typography>
//   );
// }
