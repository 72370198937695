import React, { useEffect, useState } from 'react';
import styles from './ProfessionalInfo.module.css';
import { getUserProfile } from '../../../Middleware/CandidateApis';
import { getToken } from '../../../Middleware/Constans';

const CertificationsComponent = () => {
  const [certifications, setCertifications] = useState([]);
  const token = getToken();

  useEffect(() => {
    getUserProfile(token)
      .then((res) => {
        if (res && res.applicant_profile && Array.isArray(res.applicant_profile.certifications)) {
          setCertifications(res.applicant_profile.certifications);
        } else {
          setCertifications([]); // Fallback to an empty array
        }
      })
      .catch((error) => {
        console.error("Failed to fetch certifications data:", error);
        setCertifications([]); // Fallback to an empty array in case of an error
      });
  }, [token]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'short' });
  };

  return (
    <div className='Certifications'>
      <h6 className={styles.textProfessional}>Certifications</h6>
      <ul>
        {certifications.map((cert, index) => (
          <li key={index} className='d-flex flex-column'>
            <h5 className='languageColor'>{cert.title}</h5>
            <span>From: {cert.organization}</span>
            <span>{formatDate(cert.date)}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CertificationsComponent;
