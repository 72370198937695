import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import line from "../../images/assets/allJobsUnderLine.svg";
import { getJobs } from "../../Middleware/CompanyApis";
import { getToken, getUserData } from "../../Middleware/Constans";
import styles from "./AllJobs.module.css";
import { getComapnyProfile, getJobEmployment, getJobLevel, getJobLocation, getUserProfile } from '../../Middleware/CandidateApis';
import Loading from "../../components/Loading/Loading";

export const AllJobs = () => {
    const [allJobs, setAllJobs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [filters, setFilters] = useState({
        employment: [],
        location: [],
        category: [],
        level: []
    });
    const [loading, setLoading] = useState(true);
    const [employmentTypes, setEmploymentTypes] = useState([]);
    const [locations, setLocation] = useState([]);
    const [JobLevel, setJobLevel] = useState([]);

    const navigate = useNavigate();
    const token = getToken();

    const userData = getUserData();

    const handleApplyClick = (companyName, jobSlug) => {
        navigate(`/job-details/${companyName}/${jobSlug}`);
    };
    useEffect(() => {
        if (userData.user_type === "company") {
            getComapnyProfile(userData.company_name).then((res) => {

                if (res.company_profile.completed === false) {
                    window.location.href = (`/companyProfile/${userData.company_slug}/editProfile`);
                }
            }).catch(error => {
                console.error("Error fetching company profile:", error);
            });
        }
    }, [userData, navigate]);

    useEffect(() => {
        if (userData.user_type === "applicant") {
            getUserProfile(token).then((res) => {

                if (res.completed === false) {
                    window.location.href = (`/user-profile/edit-profile`);
                }
            }).catch(error => {
                console.error("Error fetching company profile:", error);
            });
        }
    }, [userData, navigate]);
    useEffect(() => {
        async function fetchEmploymentTypes() {
            try {
                const types = await getJobEmployment();
                console.log('types', types);
                setEmploymentTypes(types.results);
            } catch (error) {
                console.error("Failed to fetch employment types", error);
            }
        }

        fetchEmploymentTypes();
    }, []);
    useEffect(() => {
        async function fetchLocation() {
            try {
                const types = await getJobLocation();
                console.log('types', types);
                setLocation(types.results);
            } catch (error) {
                console.error("Failed to fetch employment types", error);
            }
        }

        fetchLocation();
    }, []);

    useEffect(() => {
        async function fetchJobLevel() {
            try {
                const types = await getJobLevel();
                console.log('types', types);
                setJobLevel(types.results);
            } catch (error) {
                console.error("Failed to fetch employment types", error);
            }
        }

        fetchJobLevel();
    }, []);





    const handleFilterChange = (event) => {
        const { name, value, checked } = event.target;
        setFilters((prevFilters) => {
            const updatedFilter = checked
                ? [...prevFilters[name], value]
                : prevFilters[name].filter((item) => item !== value);

            return { ...prevFilters, [name]: updatedFilter };
        });
    };

    useEffect(() => {
        const fetchJobs = async () => {
            setLoading(true);  // Start loading
            try {
                const res = await getJobs(filters);


                // Sort jobs by date, assuming the date field is 'created_at'
                const sortedJobs = res.results.sort((a, b) => new Date(b.created) - new Date(a.created));

                setAllJobs(sortedJobs);
                setLoading(false);  // End loading
            } catch (error) {
                console.error(error);
                setLoading(false);  // End loading
            }
        };

        fetchJobs();
    }, [filters]);

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const requestOptions = {
                    method: "GET",
                    redirect: "follow"
                };

                const response = await fetch("https://hiringstg.eyouthlearning.com/job/job-category/", requestOptions);
                const result = await response.json();
                setCategories(result.results);
            } catch (error) {
                console.error("Failed to fetch categories:", error);
            }
        };

        fetchCategories();
    }, []);

    return (
        <div className={styles.allJobsContainer}>
            <div className={styles.allJobsHeader}>
                <div className="d-flex">
                    <h2>Find your</h2>
                    <div className="d-flex flex-column">
                        <h4>dream Job</h4>
                        <img src={line} alt="under-line" />
                    </div>
                </div>
                <h5>
                    Find your next career at companies like HubSpot, Nike, and Dropbox
                </h5>
            </div>

            <div className={`container-fluid ${styles.jobsAndFiltersContainer}`}>
                <aside className={styles.filterSideBar}>
                    <Accordion defaultExpanded className={styles.accordionContainer}>
                        <AccordionSummary
                            className={styles.test1}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h3>Type of Employment</h3>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails}>
                            <ul>
                                {employmentTypes.map((type) => (
                                    <li key={type.slug} className='my-2'>
                                        <input
                                            type="checkbox"
                                            id={type.slug}
                                            name="employment"
                                            value={type.slug}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor={type.slug}>
                                            {type.name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>

                    </Accordion>
                    <Accordion defaultExpanded className={styles.accordionContainer}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h3>Location</h3>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails}>
                            <ul>
                                {locations.map((type) => (
                                    <li key={type.slug} className='my-2'>
                                        <input
                                            type="checkbox"
                                            id={type.slug}
                                            name="employment"
                                            value={type.slug}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor={type.slug}>
                                            {type.name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded className={styles.accordionContainer}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h3>Categories</h3>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails}>
                            <ul>
                                {categories.map((category) => (
                                    <li className='my-2' key={category.id}>
                                        <input
                                            type="checkbox"
                                            id={category.slug}
                                            name="category"
                                            value={category.slug}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor={category.slug}>
                                            {category.name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded className={styles.accordionContainer}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1-content"
                            id="panel1-header"
                        >
                            <h3>Job Level</h3>
                        </AccordionSummary>
                        <AccordionDetails className={styles.accordionDetails}>
                            <ul>
                                {JobLevel.map((level) => (
                                    <li key={level.slug} className='my-2'>
                                        <input
                                            type="checkbox"
                                            id={level.slug}
                                            name="employment"
                                            value={level.slug}
                                            onChange={handleFilterChange}
                                        />
                                        <label htmlFor={level.slug}>
                                            {level.name}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </AccordionDetails>
                    </Accordion>
                </aside>
                <main className={`container-fluid ${styles.listedJobsContainer}`}>
                    <div className={styles.listedJobsHeader}>
                        <div>
                            <h2>All Jobs</h2>
                            {loading ? (
                                <Loading />
                            ) : (
                                <h4>Showing {allJobs.length} results</h4>
                            )}
                        </div>
                    </div>

                    {loading ? (
                        <p>Loading jobs...</p>
                    ) : allJobs.length === 0 ? (
                        <p>No jobs posted yet. Please check back later.</p>
                    ) : (
                        allJobs.map((job) => (
                            <div key={job.id} className={styles.jobsListCard}>
                                <div className={styles.jobInfo}>
                                    <img src={job.company.logo} alt={`${job.company.name} Logo`} />
                                    <div className="d-flex flex-column gap-2">
                                        <h2>{job.title}</h2>
                                        <h4>
                                            {job.company.name}.{" "}
                                            {job.type_of_job_location && job.type_of_job_location[0] ? job.type_of_job_location[0].name : "Unknown Location"}{" "}
                                        </h4>
                                        <div className="d-flex gap-3">
                                            <h3>
                                                {job.type_of_employment && job.type_of_employment[0] ? job.type_of_employment[0].name : "Unknown Employment Type"}
                                            </h3>
                                            <h3>{job.job_category && job.job_category[0] ? job.job_category[0].name : "Unknown Category"}</h3>
                                        </div>
                                    </div>
                                </div>
                                <button
                                    className={styles.applyBtn}
                                    onClick={() => handleApplyClick(job.company.slug, job.slug)}
                                >
                                    {userData.user_type === 'company' ? 'View' : 'Apply'}
                                </button>
                            </div>
                        ))
                    )}
                </main>
            </div>
        </div>
    );
};
