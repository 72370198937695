import React from "react"; 
import TableBuilder from './TableBuilder';
import CustomChip from "./CustomChip";
import { Box } from "@mui/system";
import TotalNumberCard from "./TotalNumberCard";
import CompanyFilter from "./CompanyFilter";
import VacancyStatsFilter from "./VacancyStatsFilter";

// Status Chip component
const createTotalStatusNumberChips = (numOfActive, numOfCompleted, numOfArchived) => {
    const colors = 
    {
        "Active": {
            bgColor:"#FFF3DB", text: "#F2994A"
        }, 
        "Completed": { 
            bgColor: "#BBF3E0", text:"#12A474"
        }, 
        "Archived":{
            bgColor: "#FFDBDB", text: "#EB5757"
        } 
    }
  return(
    <Box sx={{display:"flex", flexDirection:"row", justifyContent:"center"}}>
        <CustomChip text={numOfActive} color= {colors["Active"]} width="15px" height="18px"/>
        <Box sx={{width:"0.5625em"}}></Box>
        <CustomChip text={numOfCompleted} color= {colors["Completed"]} width="15px" height="18px"/>
        <Box sx={{width:"0.5625em"}}></Box>
        <CustomChip text={numOfArchived} color= {colors["Archived"]} width="15px" height="18px"/>
    </Box>
  )
}

// MOCK DATA: TO BE REPLACED WITH DATA FROM API
const rows = [
  {number:'123',col1: 'GertrudePabst@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)},
  {number:'43', col1:'AgnethTaube@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)},
  {number:'123',col1: 'GertrudePabst@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)},
  {number:'43',col1: 'AgnethTaube@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)},
  {number:'123', col1:'GertrudePabst@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)},
  {number:'434',col1: 'ImmanuelPlank@gmail.com', col2:createTotalStatusNumberChips(10, 5, 3)}
];

const PartnersAccounts = () => {
    return (
        <Box sx={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", gap:"20px"}}>
            <Box sx={{display:"flex", flexDirection:"column", width:"90%", alignItems:"center", justifyContent:"center", gap:"20px"}} mt={5}>
                <Box sx={{display:"flex", flexDirection:"row", width:"80%", gap:"20px"}}>
                    <CompanyFilter />
                    <VacancyStatsFilter />
                </Box>
                <Box sx={{display:"flex", flexDirection:"row", width:"50%" }}>
                    <TotalNumberCard />
                </Box>
            </Box>
            <TableBuilder rows={rows} titles={["#", "Account", "#Vacancies"]} />
        </Box>
    )
}
export default PartnersAccounts;