import { Button, Typography } from "@mui/material"
import { Box } from "@mui/system"
import React from "react"
import FilterListIcon from '@mui/icons-material/FilterList'

const VacancyStatsFilter = () =>{
    return (
        <Button type="button" sx={{
            border: "0.411782px solid #EAEEF4",
            boxShadow: "0px 2.79149px 2.79149px rgba(0, 0, 0, 0.25)",
            display:"flex", 
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            textTransform:"capitalize"
            }}
            py={1}
            px={2}>
                <Box sx={{display:"flex", flexDirection:"row"}}>
                <FilterListIcon sx={{color:"#EB7A12"}}/>
                <Typography color="#263997">Filter By Vacancy Stats</Typography>
                </Box>
            </Button>
    )
}
export default VacancyStatsFilter